import React, { useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import history from '../../history';
import Navbar from '../../components/Navbar';
import SearchBar from '../../components/SearchBar';
import ArtistInfoTile from '../../components/ArtistInfoTile';
import LineDivider from '../../components/LineDivider';
import FavoriteHeart from '../../components/FavoriteHeart';
import ContestTileLarge from '../../components/ContestTileLarge';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer';
import useScrollTop from '../../hooks/useScrollTop';
import useRequest from '../../hooks/useRequest';

function Artist(props) {
  useScrollTop(props);
  const id = props.match.params.id;
  const [req, _fetch, updateBandPage] = useRequest({
    type: 'get',
    path: `/band_pages/${id}`,
    serializer: (res) => res.band_page
  });

  const favoriteHeartCallback = (value) => {
    updateBandPage(prev => {
      const ret = Object.assign({}, prev);
      ret.data.favorited = value;
      return ret;
    });
  }

  useEffect(() => {
    if (req.errors) {
      history.replace('/404');
    }
  }, [req.errors]);

  const submitSearch = (value) => {
    history.push(`/search?q=${value}`);
  };

  // build dom nodes
  let infoTile,
      activeCampaigns,
      pastCampaigns;

  if (req.loading) {
    infoTile = <Loading className="mt-6" />;
    activeCampaigns = <Loading />;
  } else if (req.data) {
    infoTile = <ArtistInfoTile artist={req.data} favoriteHeartCallback={favoriteHeartCallback} />;
    activeCampaigns = (
      <div className="flex justify-between shadow-sm py-6 px-6 bg-white rounded-sm">
        <span className="pr-3">
          { req.data.favorited ?
            "You added this artist to your favorites. We'll let you know when they launch any future campaigns." :
            "Click the heart to save to your favorite artists; you'll be notified of future campaigns." }
        </span>
        <FavoriteHeart bandPage={req.data} callback={favoriteHeartCallback} />
      </div>
    )

    if (req.data.campaigns && req.data.campaigns.length) {
      const ended = req.data.campaigns.filter(c => c.ended || c.canceled);
      const active = req.data.campaigns.filter(c => !c.ended && !c.canceled);

      if (active.length) {
        activeCampaigns = (
          <div className="space-y-6">
            {active.map(c => <ContestTileLarge contest={c} key={c.id} />)}
          </div>
        );
      }

      if (ended.length) {
        pastCampaigns = (
          <Fragment>
            <LineDivider left="PAST CAMPAIGNS" className="py-6 pt-8" />
            <div className="space-y-6 opacity-75">
              {ended.map(c => <ContestTileLarge contest={c} key={c.id} />)}
            </div>
          </Fragment>
        );
      }
    }
  }

  function title(bandPage) {
    return `${bandPage.band.name} - Tunespeak`;
  }

  function description(bandPage) {
    return `Rewards from your favorite artists.`;
  }

  return (
    <Fragment>
      {req.data && (
        <Helmet>
          <title>{req.data.band.name} - Tunespeak</title>

          <meta property="og:title" content={title(req.data)} />
          <meta property="og:description" content={description(req.data)} />
          <meta property="og:image" content={req.data.band.photo} />

          <meta name="twitter:title" content={title(req.data)} />
          <meta name="twitter:image" content={req.data.band.photo} />
          <meta name="twitter:description" content={description(req.data)} />
        </Helmet>
      )}

      <div className="bg-gray-200 min-h-screen">
        <Navbar className="bg-blue-gradient text-white" />
        <div className="mx-auto max-w-screen-lg pt-6 pb-12 px-3 sm:px-16">
          <SearchBar onSubmit={submitSearch} />
          {infoTile}
          <LineDivider left="CAMPAIGNS" className="py-6 pt-8" />
          {activeCampaigns}
          {pastCampaigns}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Artist;
