import React from 'react';
import PropTypes from 'prop-types';
import { DateTime, Interval } from 'luxon';

function EndingSoonBanner(props) {
  const { endsAt } = props;
  const daysRemaining = props.daysRemaining || 3;
  const int = Interval.fromDateTimes(
    DateTime.local(),
    DateTime.fromISO(endsAt)
  ).length('days');

  return int < daysRemaining ? (
    <div className="absolute top-0 left-0 p-2 text-white text-xs bg-pink-600">
      <i className="icon-clock"></i> ENDING SOON
    </div>
  ) : null;
}

EndingSoonBanner.propTypes = {
  endsAt: PropTypes.string.isRequired,
  daysRemaining: PropTypes.number
};

export default EndingSoonBanner;
