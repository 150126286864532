import React, { Fragment } from 'react';
import useScrollTop from '../../hooks/useScrollTop';
import constants from "../../constants";

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import AwsMarkdown from "../../components/AwsMarkdown";

function Terms(props) {
  useScrollTop(props);
  const id = props.match.params.id;
  const bucket = constants.RULES_BUCKET;

  return (
    <Fragment>
      <div className="bg-gray-100 min-h-screen">
        <Navbar className="bg-blue-gradient text-white"/>
        <div className="max-w-screen-lg mx-auto py-10 px-6" bablic-exclude="true">
          <div className="AwsMarkdown"><h1>Official Rules</h1></div>
          {bucket ? <AwsMarkdown bucket={bucket} file={`contest_rules/${id}.txt`} /> :
                    <AwsMarkdown url={`${constants.API_ROOT}/tmp_file_rules/${id}`} />}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Terms;