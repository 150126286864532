import React, { Fragment, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import useScrollTop from '../../hooks/useScrollTop';
import useRequest from '../../hooks/useRequest';

function UnsubscribeRegistrationEmail(props) {
  useScrollTop(props);

  const search = queryString.parse(props.location.search);
  const { id, type, token } = search;

  const [registration, fetchRegistration] = useRequest();
  const [unsubscription, unsubscribe] = useRequest();
  
  // Fetch unsub status & form name
  useEffect(() => {
    if (id && token) {
      fetchRegistration({type: 'get', path: `/registrations/unsubscribe_email?id=${id}&token=${token}`});
    }
  }, [id, token]);

  const onUnsubscribe = () => {
    unsubscribe({type: 'post', path: `/registrations/unsubscribe_email?id=${id}&token=${token}`});
  };

  const content = (() => {
    const invalid = (
      <div className="max-w-screen-sm mx-4">
        <h1 className="text-2xl md:text-3xl font-thin mb-4">
          Invalid unsubscription parameters
        </h1>
        Please click the <span className="font-medium italic">UNSUBSCRIBE</span> link in the footer of the original email.
      </div>
    );

    // if invalid paramss
    if (!id || !token) return invalid;

    // fetching registration by id & token
    if (registration.loading) {
      return <Loading className="flex flex-grow justify-center" />;
    }

    // no registration found
    if (registration.complete && !registration.data) return invalid;

    // registration found
    if (registration.complete && registration.data) {
      const { form_type, form_name, email_enabled } = registration.data.registration;

      const unsubButtons = (() => {
        if (!email_enabled) {
          return <div><i className="icon-check" /> You've already unsubscribed!</div>
        }

        if (unsubscription.loading) {
          return <div>Unsubscribing...</div>;
        }

        if (unsubscription.complete) {
          if (unsubscription.success) {
            return <div><i className="icon-check" /> Success! You've been unsubscribed.</div>
          }
          return (
            <div>
              There was an error unsubscribing you. Please try again by clicking on <span className="font-medium italic">UNSUBSCRIBE</span> in the footer of the original email.
            </div>
          );
        }

        return (
          <Fragment>
            <Link to="/" className="block sm:inline-block mb-4 border border-black py-2 sm:w-1/3 hover:border-blue-700 hover:text-white hover:bg-blue-700">
              CANCEL
            </Link>
            <button
              className="block sm:inline-block sm:ml-4 border border-black py-2 w-full sm:w-1/3 hover:border-blue-700 hover:text-white hover:bg-blue-700"
              onClick={onUnsubscribe}
            >
              UNSUBSCRIBE
            </button>
          </Fragment>
        );
      })();
      
      return (
        <div className="max-w-screen-sm mx-4">
          <h1 className="text-2xl md:text-3xl font-thin">
            Would you like to unsubscribe from future emails about <span className="font-medium italic">{form_name}</span>?
          </h1>
          <div className="my-8">
            {unsubButtons}
          </div>
        </div>
      )
    }
  })();

  
  return (
    <Fragment>
      <div className="bg-gray-200 min-h-screen">
        <Helmet>
          <title>Unsubscribe Email - Tunespeak</title>
        </Helmet>
        <Navbar className="bg-blue-gradient text-white" />
        <div className="flex flex-col justify-center items-center my-20 text-center">
          {content}
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default UnsubscribeRegistrationEmail;
