import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <div className="Footer bg-gray-900">
      <div className="Footer__bg-mob">
        <div className="Footer__bg-arch">
          <div className="text-white px-8 py-10 max-w-screen-lg mx-auto lg:px-0">
            <div className="flex flex-wrap">
              <section className="w-full sm:w-1/3 mb-4 sm:mb-20">
                <header className="font-light text-gray-500 mb-1">NAVIGATE</header>
                <div className="links">
                  <Link to="/" className="block text-white leading-normal hover:text-blue-400">Home</Link>
                  <Link to="/for-artists/demo-request" className="block text-white leading-normal hover:text-blue-400">Request a Meeting</Link>
                  <a href="http://support.tunespeak.com/support/home" className="block text-white leading-normal hover:text-blue-400">Help</a>
                  <Link to="/sports" className="block text-white leading-normal hover:text-blue-400">Sports</Link>
                  <Link to="/jobs" className="block text-white leading-normal hover:text-blue-400">Jobs</Link>
                  <Link to="/tos" className="block text-white leading-normal hover:text-blue-400">Terms</Link>
                  <Link to="/privacy" className="block text-white leading-normal hover:text-blue-400">Privacy Policy</Link>
                  <Link to="/about-our-ads" className="block text-white leading-normal hover:text-blue-400">About Our Ads</Link>
                  <div className="pt-1">
                    <a href="https://twitter.com/tunespeak" target="_blank" rel="noopener noreferrer" className="text-white mb-3 pr-1 leading-normal hover:text-blue-400"><i className="icon-twitter"></i></a>
                    <a href="http://tunespeakapp.tumblr.com/" target="_blank" rel="noopener noreferrer" className="text-white mb-3 pr-1 leading-normal hover:text-blue-400"><i className="icon-tumblr"></i></a>
                    <a href="https://www.instagram.com/tunespeak/" target="_blank" rel="noopener noreferrer" className="text-white pr-1 mb-3 leading-normal hover:text-blue-400"><i className="icon-instagram"></i></a>
                    <a href="https://www.facebook.com/tunespeak/" target="_blank" rel="noopener noreferrer" className="text-white pr-1 mb-3 leading-normal hover:text-blue-400"><i className="icon-facebook"></i></a>
                  </div>
                </div>
              </section>

              <section className="w-full sm:w-1/3 mb-56 sm:mb-20">
                <header className="font-light text-gray-500 mb-1">CONTACT US</header>
                <div className="leading-normal mb-4">
                  <address>
                    P.O. Box 434016<br />
                    St. Louis, MO 63143
                  </address>
                </div>

                <div className="leading-normal mb-4">
                  <header className="font-light text-gray-500 mb-1">ARTIST INQUIRIES</header>
                  <a href="mailto:artist@tunespeak.com" className="block text-white hover:text-blue-400 pr-1 leading-normal">artist@tunespeak.com</a>
                </div>

                <div className="leading-normal mb-4">
                  <header className="font-light text-gray-500 mb-1">CUSTOMER SERVICE</header>
                  <a href="mailto:support@tunespeak.com" className="block text-white hover:text-blue-400 pr-1 leading-normal">support@tunespeak.com</a>
                </div>
              </section>
              <section>&nbsp;</section>
            </div>
            <div className="text-xs text-gray-500">
             &copy; {Math.max(2023, new Date().getFullYear())} Tunespeak, LLC. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
