import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import makeAsyncScriptLoader from "react-async-script";
import useScrollTop from "../../hooks/useScrollTop";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

function RequestMeeting(props) {
  const { src, title } = props;
  useScrollTop(props);

  return (
    <Fragment>
      <Helmet>
        <title>{title || "Tunespeak / Request Meeting"}</title>
      </Helmet>
      <div className="min-h-screen">
        <Navbar className="bg-blue-gradient text-white" />
        <iframe
          className="airtable-embed airtable-dynamic-height w-full border-none"
          src={src}
          frameBorder="0"
          height="1167"
        />
      </div>
      <Footer />
    </Fragment>
  );
}

export default makeAsyncScriptLoader(
  "https://static.airtable.com/js/embed/embed_snippet_v1.js",
  {}
)(RequestMeeting);
