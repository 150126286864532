import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from '../../pages/Home';
import World from '../../pages/World';
import Search from '../../pages/Search';
import Artist from '../../pages/Artist';
import Presale from '../../pages/Presale';
import PresaleGroup from '../../pages/PresaleGroup';
import Form from '../../pages/Form';
import Waitlist from '../../pages/Waitlist';
import Tour from '../../pages/Tour';
import Privacy from '../../pages/Privacy';
import Terms from '../../pages/Terms';
import AboutOurAds from '../../pages/AboutOurAds';
import About from '../../pages/About';
import Sports from "../../pages/Sports";
import Page404 from '../../pages/Page404';
import Campaigns from '../../pages/Campaigns';
import Settings from '../../pages/Settings';
import WriteIn from '../../pages/WriteIn';
import AcceptInvite from '../../pages/AcceptInvite';
import UnsubscribeRegistrationEmail from '../../pages/UnsubscribeRegistrationEmail';
import RequestMeeting from '../../pages/RequestMeeting';
import Jobs from '../../pages/Jobs';
import Rules from '../../pages/Rules';
import ErrorBoundary from '../ErrorBoundary';
import Loading from '../Loading';
import Localization from '../Localization';
import ProtectedRoute from '../ProtectedRoute';
import CookieBanner from '../CookieBanner';
import constants from '../../constants';
import history from '../../history';
import AuthContext from '../../contexts/AuthContext';
import socialPostImg from '../../assets/images/social_post.png';
import useRequest from '../../hooks/useRequest';

export function App() {
  const [sessionReq, refetchSession, updateSession] = useRequest({
    path: '/sessions'
  });

  return (
    <AuthContext.Provider
      value={{
        session: sessionReq,
        reload: refetchSession,
        update: updateSession,
      }}
    >
      <ErrorBoundary component="App">
        <Helmet>
          <meta
            property="og:description"
            content="Millions of fans rewarded by their favorite artists."
          />
          <meta
            property="og:title"
            content="Tunespeak - Win for being a fan."
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={`${constants.REACT_APP_URL}${socialPostImg}`}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />

          <meta
            name="twitter:title"
            content="Tunespeak - Win for being a fan."
          />
          <meta
            name="twitter:image"
            content={`${constants.REACT_APP_URL}${socialPostImg}`}
          />
          <meta name="twitter:site" content="@tunespeak" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:domain" content="https://www.tunespeak.com" />
          <meta
            name="twitter:description"
            content="Millions of fans rewarded by their favorite artists."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Localization session={sessionReq} />
        {sessionReq.loading && (
          <div className="min-h-screen flex flex-col justify-center items-center">
            <Loading />
          </div>
        )}
        {sessionReq.complete && (
          <div>
            <Router history={history}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/search" component={Search} />
                <Route path="/artist/:id" component={Artist} />
                <Route path="/presale/:id" component={Presale} />
                <Route path="/presale/:id/enter-to-win" component={Presale} />
                <Route path="/tour-presale/:id" component={PresaleGroup} />
                <Route path="/tour-listing/:id" component={Tour} />
                <Route path="/form/:id" component={Form} />
                <Route path="/form/:id/enter-to-win" component={Form} />
                <Route path="/waitlist/:id" component={Waitlist} />
                <Route path="/write-in/:id" component={WriteIn} />
                <Route path="/rules/:id" component={Rules} />
                <Route exact path="/world/:country" component={World} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/tos" component={Terms} />
                <Route exact path="/about-our-ads" component={AboutOurAds} />
                <Route exact path="/about" component={About} />
                <ProtectedRoute path="/campaigns" component={Campaigns} />
                <Route path="/settings" component={Settings} />
                <Route
                  exact
                  path="/unsubscribe/registration-email"
                  component={UnsubscribeRegistrationEmail}
                />
                <Route
                  exact
                  path="/for-artists/demo-request"
                  render={(props) => (
                    <RequestMeeting
                      {...props}
                      src="https://airtable.com/embed/shrY8oXtNXVjHvwO6?backgroundColor=blue"
                    />
                  )}
                />
                <Route
                  exact
                  path="/sports/demo-request"
                  render={(props) => (
                    <RequestMeeting
                      {...props}
                      src="https://airtable.com/embed/apptA8EMkssFsX8Lg/shrcMhxjuAEYnbzhV?backgroundColor=blue"
                      title="Sports by Tunespeak / Request Meeting"
                    />
                  )}
                />
                <ProtectedRoute
                  path="/accept-invite/:id"
                  component={AcceptInvite}
                />
                <Route exact path="/404" component={Page404} />
                <Route path="/jobs" component={Jobs} />
                <Route path="/sports" component={Sports} />
                <Route path="*" component={Page404} />
              </Switch>
            </Router>
          </div>
        )}
        {sessionReq.complete && <CookieBanner />}
      </ErrorBoundary>
    </AuthContext.Provider>
  );
}

export default App;
