import React from 'react';
import FavoriteHeart from '../FavoriteHeart';
import artistPlacholderImg from '../../assets/images/artist_placeholder.svg';

function ArtistInfoTile(props) {
  const { artist, favoriteHeartCallback } = props;
  const { band } = artist;
  const photoSrc = band.photo === 'https://s3.amazonaws.com/tunespeak/defaults/default_band_small.png' ? artistPlacholderImg : band.photo;
  let source = null;

  const bioSource = (() => {
    if (!band.bio.url) {
      return <span className="text-gray-500">{band.bio.source}</span>
    }

    return (
      <a href={band.bio.url} target="_blank" rel="noopener noreferrer" className="link-gray">
        {band.bio.source} <i className="icon-leave"></i>
      </a>
    );
  })();

  const socialLinks = band.external_links.map((link, i) => {
    return (
      <a
        href={link.url}
        key={i}
        target="_blank"
        rel="noopener noreferrer"
        className="link-blue mx-2 text-lg"
      >
        <i className={`icon-${link.icon}`} />
      </a>
    );
  });

  return (
    <div className="bg-white rounded-sm shadow-sm text-center mt-20 md:mt-24 relative">
      <div className="border border-white inline-block rounded-full -mt-12 md:-mt-16">
        <div className="border-6 border-gray-200 inline-block rounded-full">
        <img className="bg-gray-500 rounded-full w-28 h-28 sm:w-40 sm:h-40" src={photoSrc} alt="" />
        </div>
      </div>
      <div className="pt-2 pb-6">
        <header>
          <h1 className="font-normal text-2xl">{band.name}</h1>
          <FavoriteHeart bandPage={artist} callback={favoriteHeartCallback} className="absolute top-0 right-0 p-4 md:p-6" />
        </header>
       
        { band.bio.text && 
          <p className="pt-3 px-6 max-w-screen-sm mx-auto text-xs">
            {band.bio.text} {band.bio.source && bioSource}
          </p>
        }
         { socialLinks.length > 0 && 
          <div className="mt-3">
            {socialLinks}
          </div>
        }
      </div>
    </div>
  );
}

export default ArtistInfoTile;
