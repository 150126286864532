import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import { DateTime } from 'luxon';
import ContestPrizeIcon from '../ContestPrizeIcon';
import BackgroundImage from '../BackgroundImage';
import placeholderImg from '../../assets/images/campaign_placeholder.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/icon_clock.svg';

function ContestTileLarge(props) {
  const { contest } = props;
  const { url, photo, prize_icon, title, ends_at, ended, canceled } = contest;

  return (
    <a href={url} className="flex flex-col sm:flex-row relative bg-white overflow-hidden rounded-sm shadow-sm transition-colors duration-100 hover:text-blue-700">
      
      {/* Mobile image + icon */}
      <div className="sm:hidden">
        <img src={photo || placeholderImg} className="w-full" />
        <div className="relative flex justify-center">
          <ContestPrizeIcon icon={prize_icon} className="absolute" style={{top: -30}} />
        </div>
      </div>

      {/* Desktop image + icon */}
      <div className="hidden sm:block w-1/4 min-h-full p-1 border-box">
        <BackgroundImage src={photo || placeholderImg} size="cover" className="w-full h-full" />
      </div>
      <div className="hidden sm:flex relative flex-col justify-center min-h-full">
        <ContestPrizeIcon icon={prize_icon} className="absolute -ml-8" />
      </div>

      <div className="p-4 pt-6 sm:px-8 min-w-64 flex-1">
        <p bablic-exclude="true" className="leading-5">{title}</p>
        <div className="divider-fade-right my-4"></div>
        <span className="text-xs text-gray-500 flex items-center">
          {canceled ?
            <Fragment>
              <i className="icon-not-allowed h-3 w-3 mr-1" />
              CANCELED
            </Fragment>
            :
            <Fragment>
              <ClockIcon className="h-3 w-3 mr-2" />
              <span className="hidden sm:inline">
                {ended ? 'Ended' : 'Ends'}&nbsp;
              </span>
              <TimeAgo date={ends_at} />
            </Fragment>
          }
        </span>
      </div>
    </a>
  );
}

ContestTileLarge.propTypes = {
  contest: PropTypes.shape({
    title: PropTypes.string.isRequired,
    photo: PropTypes.string,
    url: PropTypes.string.isRequired,
    ends_at: PropTypes.string.isRequired,
    prize_icon: PropTypes.string
  })
};

export default ContestTileLarge;
