import React, { Fragment, useEffect, useState, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import history from '../../history';
import constants from '../../constants';
import Navbar from '../../components/Navbar';
import RequiresPassword from '../../components/RequiresPassword';
import useScrollTop from '../../hooks/useScrollTop';
import useRequest from '../../hooks/useRequest';
import ENV from '../../constants';
import './Waitlist.scss';

import { initialize } from '@tunespeak/ts-presale-registration';
const WaitlistRegistration = React.lazy(() =>
  import('@tunespeak/ts-presale-registration').then((module) => ({
    default: module.Waitlist
  }))
);

initialize({
  googleAnalytics: {
    gaMeasurementId: ENV.KEYS.googleAnalytics.gaMeasurementId
  },
  facebook: {
    appId: ENV.KEYS.facebook.appId
  },
  emberRoot: ENV.EMBER_APP_URL,
  apiRoot: ENV.API_ROOT,
  authTokenName: ENV.KEYS.authTokenName
});

function Waitlist(props) {
  const id = props.match.params.id;
  useScrollTop(props);
  const [waitlistRequest] = useRequest({
    type: 'get',
    path: `/waitlists/${id}`
  });

  const [dontLoadYet, setDontLoadYet] = useState(true);
  const waitlist = waitlistRequest?.data?.waitlist;
  const search = queryString.parse(props.location.search);

  useEffect(() => {
    if (!waitlist) return;
    const registrationUrl = waitlist.registration_url;

    if (registrationUrl) {
      const regex = new RegExp(`^${constants.REACT_APP_URL}/waitlist/${id}`);
      if (!regex.test(registrationUrl)) {
        if (!/Prerender/gi.test(navigator.userAgent)) {
          const redirectUrl = queryString.stringifyUrl({
            url: registrationUrl,
            query: search
          });
          return window.location.replace(redirectUrl);
        }
      }
    }

    setDontLoadYet(false);
  }, [waitlist]);

  useEffect(() => {
    if (waitlistRequest.errors) {
      // 403 handled by RequiresPassword
      if (waitlistRequest.errors?.status !== 403) {
        history.replace('/404');
      }
    }
  }, [waitlistRequest.errors]);

  if (!dontLoadYet && waitlist) {
    const search = window.location.search;
    const {
      name,
      og_title,
      og_description,
      facebook_post_photo,
      theme_css,
      header_photo,
      background_photo
    } = waitlist;

    return (
      <Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <title bablic-exclude="true">{name} - Tunespeak</title>
          <meta property="og:title" bablic-exclude="true" content={og_title} />
          {og_description && (
            <meta
              property="og:description"
              bablic-exclude="true"
              content={og_description}
            />
          )}
          <meta property="og:image" content={facebook_post_photo} />

          <meta name="twitter:title" bablic-exclude="true" content={og_title} />
          <meta name="twitter:image" content={facebook_post_photo} />
          {og_description && (
            <meta
              name="twitter:description"
              bablic-exclude="true"
              content={og_description}
            />
          )}
          {theme_css && <style>{theme_css}</style>}
        </Helmet>

        <div className="Waitlist__bg relative bg-gray-200 min-h-screen overflow-auto">
          {background_photo && (
            <div
              className="Waitlist__bg-photo z-10 fixed inset-0 w-full h-full bg-no-repeat bg-cover bg-center"
              style={{ backgroundImage: `url(${background_photo})` }}
            />
          )}
          <Navbar className="relative bg-black text-white z-20" />
          <div
            className="Waitlist relative max-w-screen-sm mx-auto my-10 p-4 z-20"
            bablic-exclude="true"
          >
            {header_photo && (
              <img src={header_photo} alt="" className="Waitlist__photo" />
            )}
            <Suspense fallback={null}>
              <WaitlistRegistration waitlistId={id} />
            </Suspense>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <RequiresPassword errors={waitlistRequest.errors} cookie="waitlist_pwd" />
  );
}

export default Waitlist;
