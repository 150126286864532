import React, { Fragment, useEffect, useState, Suspense } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import history from '../../history';
import constants from '../../constants';
import Navbar from '../../components/Navbar';
import RequiresPassword from '../../components/RequiresPassword';
import useScrollTop from '../../hooks/useScrollTop';
import useRequest from '../../hooks/useRequest';
import { dateInPast } from '../../utils/utilities';
import ENV from '../../constants';

import { initialize } from '@tunespeak/ts-presale-registration';
const TsPresaleRegistration = React.lazy(() =>
  import('@tunespeak/ts-presale-registration').then(module => ({ default: module.Presale }))
);

initialize({
  googleAnalytics: {
    gaMeasurementId: ENV.KEYS.googleAnalytics.gaMeasurementId,
  },
  facebook: {
    appId: ENV.KEYS.facebook.appId,
  },
  emberRoot: ENV.EMBER_APP_URL,
  apiRoot: ENV.API_ROOT,
  authTokenName: ENV.KEYS.authTokenName,
});

function Presale(props) {
  const id = props.match.params.id;
  useScrollTop(props);
  const [req] = useRequest({
    type: 'get',
    path: `/presales/${id}`,
    serializer: (res) => res.presale
  });

  const [dontLoadYet, setDontLoadYet] = useState(true);
  const presale = req?.data;
  const search = queryString.parse(props.location.search);

  useEffect(() => {
    if (!presale) return;
    const registrationUrl = presale.registration_url;

    if (registrationUrl) {
      const regex = new RegExp(`^${constants.REACT_APP_URL}/presale/${id}`);
      if (!regex.test(registrationUrl)) {
        if (!/Prerender/ig.test(navigator.userAgent)) {
          const redirectUrl = queryString.stringifyUrl({url: registrationUrl, query: search});
          return window.location.replace(redirectUrl);
        }
      }
    }

    setDontLoadYet(false);
  }, [presale]);

  useEffect(() => {
    if (req.errors) {
      // 403 handled by RequiresPassword
      if (req?.errors?.status !== 403) {
        history.replace('/404');
      }
    }
  }, [req.errors]);

  if (!dontLoadYet && presale) {
    // if ended and on sale redirect to ext url
    if (presale.ends_at && presale.on_sale_at && presale.ext_on_sale_url || presale.ext_url) {
      const ended  = dateInPast(presale.ends_at);
      const onSale = dateInPast(presale.on_sale_at);
      if (ended && onSale) {
        window.location.replace(presale.ext_on_sale_url || presale.ext_url);
      }
    }

    const search = window.location.search;
    const { name, og_description, og_title, facebook_post_photo, theme_css, background_photo, header_photo, group_id } = presale;

    return (
      <Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <title bablic-exclude="true">{name} - Tunespeak</title>
          <meta property="og:title" bablic-exclude="true" content={og_title} />
          {og_description && <meta property="og:description" bablic-exclude="true" content={og_description} />}
          <meta property="og:image" content={facebook_post_photo} />

          <meta name="twitter:title" bablic-exclude="true" content={og_title} />
          <meta name="twitter:image" content={facebook_post_photo} />
          {og_description && <meta name="twitter:description" bablic-exclude="true" content={og_description} />}
          {theme_css && <style>{theme_css}</style>}
        </Helmet>

        <div className="Presale__bg relative bg-black min-h-full overflow-auto">
          {background_photo && <div className="Presale__bg-photo z-10 fixed inset-0 w-full h-full bg-no-repeat bg-cover bg-center" style={{backgroundImage: `url(${background_photo})`}} />}
          <Navbar className="relative text-white z-20" />
          <div className="Presale bg-gray-100 sm:rounded overflow-hidden relative max-w-screen-md mx-auto sm:mt-10 sm:mb-20 z-20" bablic-exclude="true">
            {header_photo && <img src={header_photo} alt="" className="Presale__photo" />}
            <div className="p-4 sm:px-6 sm:pt-6">
              <Suspense fallback={null}>
                <Switch>
                  <Route exact path="/presale/:id">
                    <Fragment>
                      {group_id && (
                        <div className="text-center">
                          <Link to={{ pathname: `/tour-presale/${group_id}`, search }} className="mb-4 tssdk-link">&larr; All Shows</Link>
                        </div>
                      )}
                      <TsPresaleRegistration presaleId={id} presale={presale} />
                    </Fragment>
                  </Route>
                  <Route exact path="/presale/:id/enter-to-win">
                    <Fragment>
                      <div className="text-center">
                        <a href={`/presale/${presale.id}${search}`} className="tssdk-link">&larr; Back</a>
                      </div>
                      <TsPresaleRegistration presaleId={id} presale={presale} initState="campaign" />
                    </Fragment>
                  </Route>
                </Switch>
              </Suspense>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <RequiresPassword errors={req.errors} cookie="presale_pwd" />
  );
}

export default Presale;