import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import history from '../../history';
import Navbar from '../../components/Navbar';
import SearchBar from '../../components/SearchBar';
import SearchResultTile from '../../components/SearchResultTile';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer';
import useScrollTop from '../../hooks/useScrollTop';
import useRequest from '../../hooks/useRequest';
import noSearchResultsImg from '../../assets/images/no_search_results.svg';

function Search(props) {
  useScrollTop(props);
  const [req, fetchBandPages] = useRequest();
  const [readableSearch, setReadableSearch] = useState(null);
  const [results, setResults] = useState([]);

  // fetch search results whenever location.search changes and set display value
  useEffect(() => {
    setReadableSearch(decodeURIComponent(props.location.search.slice(3)));
    fetchBandPages({
      type: 'get',
      path: `/band_pages${props.location.search}&limit=10`,
      serializer: (res) => res.band_pages
    });
  }, [props.location.search]);

  const submitSearch = (value) => {
    const encodedSearch = `?q=${encodeURIComponent(value)}`;
    history.push(`search${encodedSearch}`);
  };

  useEffect(() => {
    if (req.data) {
      setResults(() => {
        return req.data.map((r) => {
          return <SearchResultTile result={r} key={r.id} />;
        });
      });
    }
  }, [req.data]);

  return (
    <Fragment>
      <div className="bg-gray-200">
        <Helmet>
          <title bablic-exclude="true">
            {readableSearch ? `${readableSearch} - ` : ''}Tunespeak Search
          </title>
        </Helmet>
        <Navbar className="bg-blue-gradient text-white" />
        <div className="mx-auto max-w-screen-lg py-12 px-6 sm:px-16 min-h-screen">
          <SearchBar onSubmit={submitSearch} value={readableSearch} />
          {req.loading ? (
            <Loading className="mt-10" />
          ) : (
            <div>
              {results.length ? (
                <div className="flex flex-col justify-between sm:flex-row sm:items-end mt-6">
                  <p>
                    Showing {results.length} results for "
                    <span bablic-exclude="true" className="font-bold">
                      {readableSearch}
                    </span>
                    "
                  </p>
                  <p className="text-center bg-pink-500 py-1 px-3 rounded-full text-white text-xs mt-2 sm:mt-0">
                    Tap the heart to be notified of future campaigns
                  </p>
                </div>
              ) : (
                <div className="w-full flex flex-col justify-center items-center pt-6 text-center">
                  <img src={noSearchResultsImg} alt="" className="h-64 w-64" />
                  <p>
                    <span className="italic">
                      "
                      <span bablic-exclude="true" className="font-bold">
                        {readableSearch}
                      </span>
                      "
                    </span>{' '}
                    not found.
                  </p>
                  <p>Please check your spelling or try a different keyword.</p>
                </div>
              )}
              {results}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Search;
