import React from 'react';
import classnames from 'classnames';

import loadingImg from '../../assets/images/vinyl_loading.svg';

function Loading(props) { 
  return (
    <div className={classnames('Loading flex flex-col justify-center items-center h-full', props.className)}>
      <img src={loadingImg} alt="Loading..." className="h-24" />
    </div>
  )
}

export default Loading;
