import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function SearchBar(props) {
  const [value, setValue] = useState(props.value || '');
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (value) {
      props.onSubmit(value);
    }
  }

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  return (
    <form className="flex flex-grow items-center pb-2 border-b border-black text-black text md:text-lg" onSubmit={handleSubmit}>
      <i className="icon-search"></i>
      <input className="ml-3 w-full bg-transparent outline-none placeholder-black" type="text" placeholder="Find your favorite artist..." value={value} onChange={(e) => {setValue(e.target.value)}}></input>
      <button className="focus:outline-none focus:shadow-outline" onClick={handleSubmit}>
        <i className="icon-arrow-right"></i>
      </button>
    </form>
  );
}

SearchBar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string
}

export default SearchBar;
