import React, { Fragment } from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import useScrollTop from '../../hooks/useScrollTop';

import Ongoing from './Ongoing';
import Completed from './Completed';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

export default function Campaigns(props) {
  useScrollTop(props);

  const navLinks = [
    { to: '/campaigns/ongoing', label: 'Ongoing' },
    { to: '/campaigns/completed', label: 'Completed' },
  ];

  return (
    <Fragment>
      <div className="min-h-screen bg-gray-200">
        <div className="bg-blue-gradient">
          <Navbar className="text-white" />
          
          <div className="max-w-screen-lg mx-auto w-full px-4 lg:px-0">
            <p className="text-3xl font-thin text-white mt-6">My Campaigns</p>
            {navLinks.map(({to, label}) => (
              <NavLink
                key={to}
                to={to}
                className="inline-block py-2 mr-6 text-white"
                activeClassName="border-b-4 border-white border-solid font-medium"
              >
                {label}
              </NavLink>
            ))}
          </div>
        </div>
        
        <Switch>
          <Route
            exact
            path="/campaigns/ongoing"
            component={Ongoing}
          />
          <Route
            exact
            path="/campaigns/completed"
            component={Completed}
          />
          <Redirect to="/campaigns/ongoing" />
        </Switch>
      </div>
      <Footer />
    </Fragment>
  );
}
