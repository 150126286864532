import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import useScrollTop from "../../hooks/useScrollTop";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import screenshotsImg from "../../assets/images/about-screenshots.png"

function About(props) {
  useScrollTop(props);

  return (
    <Fragment>
      <Helmet>
        <title>Tunespeak / About</title>
      </Helmet>
      <div className="bg-gray-100 min-h-screen">
        <Navbar className="bg-blue-gradient text-white" />
        <div
          className="max-w-screen-lg mx-auto py-10 px-6"
          bablic-exclude="true"
        >
          <div className="space-y-8">
            <div className="flex justify-center">
              <svg viewBox="0 0 1363.72 144.55" xmlns="http://www.w3.org/2000/svg" className="text-blue-600 h-8">
                <g fill="currentColor">
                  <path fill="#000" d="m1286.39 67.09 67.1-67.09h-61.91l-46.51 46.5v-46.5h-46.36v144.55h46.36v-56.73l56.87 56.73h61.78z" />
                  <path fill="#000" d="m1069.84 0-72.14 144.55h51.54l7.76-15.55h77.32l7.77 15.54h51.55l-72.26-144.54zm5.16 92.87 20.59-41.32 20.59 41.32z" />
                  <path fill="#000" d="m464.07 0v36.14 15.55 36.13 20.6 36.13h46.37 77.32v-36.13h-77.32v-20.6h61.91v-36.13h-61.91v-15.55h77.32v-36.14h-77.32z" />
                  <path fill="#000" d="m0 0v36.14h43.77v108.41h46.51v-108.41h43.77v-36.14z" />
                  <path fill="#000" d="m404.75 76.09-51.55-76.09h-46.36v144.55h46.36v-76.09l51.55 76.09h46.37v-144.55h-46.37z" />
                  <path fill="#000" d="m660.45 52.09s-22.09.82-22.09-8.86c0-10.77 22.09-9.68 22.09-9.68s29.86-.68 55.77 15.27v-34.36s-16.91-14.46-56.22-14.46-65.73 18.41-65.73 48.41c0 38.05 65.73 42.41 65.73 42.41s25.5.69 25.5 10c0 7-5.18 10.36-25.5 10.36s-49.78-9.13-61-19.63v37.22s14.32 15.55 60.55 15.55 68.73-26 68.73-43.64c-.06-17.36-5.28-48.59-67.83-48.59z" />
                  <path fill="#000" d="m816.18 0h-81.41v144.55h46.37v-48.68h35c29.05 0 52.64-21.41 52.64-48s-23.55-47.87-52.6-47.87zm-15.54 64.91h-19.5v-33.91h19.5c11.73 0 21.68 7.77 21.68 17s-9.95 16.91-21.68 16.91z" />
                  <path fill="#000" d="m244.92 82.64s0 20.18-25.77 20.18-25.77-20.18-25.77-20.18v-82.64h-46.38v98.6s0 46 72.14 46 72.28-46 72.28-46v-98.6h-46.5z" />
                  <path d="m929 25.77h72.14a25.75 25.75 0 0 0 -25.81-25.77h-77.33a25.84 25.84 0 0 0 -25.78 25.77h31v20.6h-31v15.54h31v20.59h-31v15.55h31v20.73h-31a25.84 25.84 0 0 0 25.78 25.77h77.32a25.84 25.84 0 0 0 25.77-25.77h-72.09v-20.73h72.14v-15.41h-72.14v-20.73h72.14v-15.41h-72.14z" />
                </g>
              </svg>
            </div>
          
            <p>
              <span className="text-blue-600">Tunespeak is</span> the music
              industry's trusted leader in online fan engagement and has served
              thousands of artists since 2013. The Tunespeak team has the
              experience and expertise in the technical, legal, and customer
              support best practices to run successful campaigns. Tunespeak has
              helped its clients reward more than a millions music fans and
              engage and collect data on millions of music consumers.
              Tunespeak's mission is to help identify and reward passionate
              music fans through campaigns that are designed to promote tour
              awareness and increase streams, saves, follows and overall content
              consumption.
            </p>

            <img src={screenshotsImg} className="sm:float-right sm:w-1/2 sm:ml-8" />
            <section>
              <div className="text-blue-600">Campaigns</div>
              <p>
                Tunespeak is most known for gamified giveaway campaigns, which
                reward top fans around tours, album releases and off- cycle
                moments in an artist's career. Fans get credit for completing
                meaningful actions: 
              </p>
              <ul className="list-disc ml-6 py-4">
                <li>
                  Streaming, saving, pre-saving, adding to playlist and
                  following on Spotify
                </li>
                <li>Watching videos on YouTube</li>
                <li>Looking at photos on Instagram</li>
                <li>Creating “dream” set lists</li>
                <li>Answering questions with music</li>
                <li>Influencing friends to do all of the above</li>
              </ul>
              <p> 
                The most passionate fans are rewarded with tickets, merchandise
                and experiences. Tunespeak has incentivized hundreds of millions
                of meaningful actions for artists.
              </p>
              
            </section>

            <section>
              <div className="text-blue-600">Data</div>
              <p>
                Tunespeak captures interest points for all of its registered
                users to provide a powerful data set back to its artist clients.
                Some of those data points include interests in
                musicians/artists/bands, upcoming concerts, new recordings and
                general information and news about all of the above.
              </p>
            </section>

            <section>
              <div className="text-blue-600">Contact</div>
              <p>
                For more information please contact{" "}
                <a href="mailto:artist@tunespeak.com" className="link-blue">
                  artist@tunespeak.com
                </a>
                .
              </p>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default About;
