import React, { Fragment, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import useRequest from '../../hooks/useRequest';
import useSearchParams from '../../hooks/useSearchParams';
import history from '../../history';
import ENV from '../../constants';
import { ReactComponent as WishesLogo } from '../../assets/images/wishes_logo.svg';

function AcceptInvite() {
  const { id } = useParams();
  const { token } = useSearchParams();
  const [invite] = useRequest({
    type: 'get',
    path: `/manager/role_invites/${id}?token=${token}`,
    serializer: (res) => res.role_invite
  });
  const [acceptInviteReq, acceptInvite] = useRequest();
  const errors = acceptInviteReq?.errors?.data?.message;

  const handleAcceptInvite = () => {
    acceptInvite({
      type: 'post',
      path: `/manager/role_invites/${id}/accept?token=${token}`
    });
  };

  useEffect(() => {
    if (invite?.errors?.status === 404) {
      history.replace('/404');
    }
  }, [invite?.errors]);

  useEffect(() => {
    if (acceptInviteReq.success) {
      if (invite?.data?.resource_type === 'WishPrompt') {
        window.top.location = `${ENV.WISHES_APP_URL}/admin/prompt/${invite?.data?.resource_id}`;
      }
    }
  }, [acceptInviteReq.success]);

  // if missing token query-param redirect
  if (!token) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <div className="bg-gray-200 ">
        <Navbar className="bg-blue-gradient text-white" />
        <div className="flex items-center justify-center min-h-screen">
          <div className="text-center w-full max-w-md mb-12 py-6 px-4 bg-white shadow-lg">
            {invite.loading && <Loading />}
            {!invite.loading && (
              <Fragment>
                {invite?.data?.resource_type === 'WishPrompt' && (
                  <WishesLogo className="inline-block fill-current text-pink-800 h-6 mt-4 mx-4" />
                )}
                {errors && (
                  <p className="text-red-500 text-xs mt-4">*{errors}</p>
                )}
                <p className="text-sm mt-4">
                  <b className="capitalize">{invite?.data?.inviter_name}</b> has
                  invited you to manage{' '}
                  <b className="capitalize">{invite?.data?.resource_name}</b>.
                </p>
                {acceptInviteReq?.loading && (
                  <button className="btn btn-border mt-6 w-full">
                    <i className="icon-loading"></i>
                  </button>
                )}
                {!acceptInviteReq?.loading && !acceptInviteReq?.success && (
                  <button
                    className="btn btn-border mt-6 w-full"
                    onClick={handleAcceptInvite}
                  >
                    Accept Invite
                  </button>
                )}
                {acceptInviteReq.success && (
                  <button disabled className="btn btn-disabled mt-6 w-full">
                    <i className="icon-check"></i> Accepted
                  </button>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default AcceptInvite;
