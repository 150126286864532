import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { Helmet } from "react-helmet";
import useScrollTop from '../../hooks/useScrollTop';
import page404Img from '../../assets/images/404.svg';

function Page404(props) {
  useScrollTop(props);
  
  return (
    <Fragment>
      <div className="bg-gray-200 min-h-screen">
        <Helmet>
          <title>Not Found (404) - Tunespeak</title>
        </Helmet>
        <Navbar className="bg-blue-gradient text-white" />
        <div className="flex flex-col justify-center items-center my-20">
          <img src={page404Img} alt="" className="max-w-xs mb-4" />
          <Link to="/" className="link-blue"><i className="icon-arrow-left"></i> Take me home</Link>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Page404;
