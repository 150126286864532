import React from 'react';
import PropTypes from 'prop-types';

import iconBoat from '../../assets/images/icon_boat.svg';
import iconClock from '../../assets/images/icon_clock.svg';
import iconExperience from '../../assets/images/icon_experience.svg';
import iconFacetime from '../../assets/images/icon_facetime.svg';
import iconGeneric from '../../assets/images/icon_generic.svg';
import iconGift from '../../assets/images/icon_gift.svg';
import iconGuitar from '../../assets/images/icon_guitar.svg';
import iconHeadphones from '../../assets/images/icon_headphones.svg';
import iconMeetgreet from '../../assets/images/icon_meetgreet.svg';
import iconMoney from '../../assets/images/icon_money.svg';
import iconPlane from '../../assets/images/icon_plane.svg';
import iconSetlist from '../../assets/images/icon_setlist.svg';
import iconShirt from '../../assets/images/icon_shirt.svg';
import iconShoes from '../../assets/images/icon_shoes.svg';
import iconTicket from '../../assets/images/icon_ticket.svg';
import iconVinyl from '../../assets/images/icon_vinyl.svg';
import iconVip from '../../assets/images/icon_vip.svg';

function PrizeIcon(props) {
  const { icon } = props;
  let prizeIcon = iconGeneric;
  switch (icon) {
    case 'icon_boat':
      prizeIcon = iconBoat;
      break;
    case 'icon_clock':
      prizeIcon = iconClock;
      break;
    case 'icon_experience':
      prizeIcon = iconExperience;
      break;
    case 'icon_facetime':
      prizeIcon = iconFacetime;
      break;
    case 'icon_generic':
      prizeIcon = iconGeneric;
      break;
    case 'icon_gift':
      prizeIcon = iconGift;
      break;
    case 'icon_guitar':
      prizeIcon = iconGuitar;
      break;
    case 'icon_headphones':
      prizeIcon = iconHeadphones;
      break;
    case 'icon_meetgreet':
      prizeIcon = iconMeetgreet;
      break;
    case 'icon_money':
      prizeIcon = iconMoney;
      break;
    case 'icon_plane':
      prizeIcon = iconPlane;
      break;
    case 'icon_setlistt':
      prizeIcon = iconSetlist;
      break;
    case 'icon_shirt':
      prizeIcon = iconShirt;
      break;
    case 'icon_shoes':
      prizeIcon = iconShoes;
      break;
    case 'icon_ticket':
      prizeIcon = iconTicket;
      break;
    case 'icon_vinyl':
      prizeIcon = iconVinyl;
      break;
    case 'icon_vip':
      prizeIcon = iconVip;
      break;
    default:
      break;
  }
  return (
    <img src={prizeIcon} alt="" className="max-w-full max-h-full" />
  );
}

PrizeIcon.propTypes = {
  icon: PropTypes.string
}

export default PrizeIcon;
