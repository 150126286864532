import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import constants from '../../constants';
import AuthContext from '../../contexts/AuthContext';
import heartFullImg from '../../assets/images/heart_full.svg';
import heartEmptyImg from '../../assets/images/heart_empty.svg';
import TS from '../../api';
import './FavoriteHeart.scss';

const redirect = function () {
  const url = encodeURIComponent(window.location.href.toString());
  const loginURL = `${constants.EMBER_APP_URL}/login?redirect=${url}`;
  window.top.location = loginURL;
}

export function FavoriteHeart(props) {
  const { bandPage, callback, className } = props;
  const { session } = useContext(AuthContext);
  const [favorited, setFavorited] = useState(bandPage.favorited);
  const profile = session?.data?.profile;

  const toggleFavorite = shouldFavorite => {
    if (!session || !profile?.email_verified) {
      redirect();
      return;
    }
    if (shouldFavorite) {
      TS.post('favorite_bands', {
        favorite_band: { band_id: bandPage.id }
      }).then(() => {
        setFavorited(true);
        if (callback) callback(true);
      });
    } else {
      TS.delete(`favorite_bands?band_id=${bandPage.id}`, {
        band_id: bandPage.id
      }).then(() => {
        setFavorited(false);
        if (callback) callback(false);
      });
    }
  }

  useEffect(() => {
    setFavorited(bandPage.favorited);
  }, [bandPage.favorited]);

  return (
    <div className={classnames('favorite-heart', className)}>
      {
        favorited ?
          <img src={heartFullImg} alt="" onClick={() => { toggleFavorite(false) }} />
        :
          <img src={heartEmptyImg} alt="" onClick={() => { toggleFavorite(true) }} />
      }
    </div>
  );
}

export default FavoriteHeart;
