import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Navbar';
import Loading from '../Loading';

function LoadingPage(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Tunespeak</title>
      </Helmet>
      <div className="bg-gray-200 h-full">
        <Navbar className="fixed w-full bg-black text-white z-20" />
        <Loading />
      </div>
    </Fragment>
  );
}

export default LoadingPage;