import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './vendor/fonts/tunespeak/style.css';
import './index.scss';
import App from './components/App';
import FacebookInitializer from './initializers/facebook';
import GoogleAnalyticsInitializer from './initializers/google-analytics';
import * as serviceWorker from './config/serviceWorker';

// Load the FB lib
FacebookInitializer();

// Load Google Analytics
GoogleAnalyticsInitializer();

ReactDOM.render((
  <App />
), document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
