import React, { useState, useContext, Fragment } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import useAsyncState from '../../../hooks/useAsyncState';
import Modal from '../../../components/Modal';
import TS from '../../../api';

export default function Birthday() {
  const { session, update: updateSession } = useContext(AuthContext);
  const profile = session?.data?.profile;
  const bday = profile?.birthday;
  const [error, setError] = useAsyncState(null);
  const [loading, setLoading] = useAsyncState(false);
  const [isEditingBirthday, setIsEditingBirthday] = useAsyncState(false);
  const [birthday, setBirthday] = useState({
    mm: bday ? bday.substring(5, 7) : null,
    dd: bday ? bday.substring(8) : null,
    yyyy: bday ? bday.substring(0, 4) : null
  });

  const handleChange = (key, val) => {
    setBirthday((prev) => {
      return {
        ...prev,
        [key]: val
      };
    });
  };

  const validateBirthday = () => {
    setError(null);
    let errors = 0;
    if (birthday.mm < 0 || birthday.mm > 12) errors += 1;
    if (birthday.dd < 0 || birthday.dd > 31) errors += 1;
    if (birthday.yyyy < 1900 || birthday.yyyy > 2100) errors += 1;
    if (errors > 0) {
      setError('* Birthday is Invalid');
      return false;
    } else {
      return true;
    }
  }

  const updateBirthday = () => {
    if (validateBirthday() && !loading) {
      setLoading(true);
      TS.put(`/user_profiles/${profile.id}`, {
        user_profile: {
          ...profile,
          birthday: `${birthday.yyyy}-${birthday.mm}-${birthday.dd}`
        }
      })
        .then((res) => {
          setLoading(false);
          setIsEditingBirthday(false);
          updateSession((prev) => {
            const ret = Object.assign({}, prev);
            ret.data.profile = res.user_profile;
            return ret;
          });
        })
        .catch(() => {
          setLoading(false);
          setError('* Birthday is Invalid');
        });
    }
  };

  return (
    <div className="flex justify-between items-center p-3 sm:p-4 border-t">
      <div>
        <p>Birthday</p>
        <p className="text-xs text-gray-600">
          {bday && (
            <Fragment>
              {bday.substring(5, 7)}/{bday.substring(8)}/{bday.substring(2, 4)}
            </Fragment>
          )}
        </p>
      </div>
      <button
        className="btn btn-border btn-round"
        onClick={() => setIsEditingBirthday(true)}
      >
        Edit
      </button>
      <Modal
        open={isEditingBirthday}
        onClose={() => setIsEditingBirthday(false)}
      >
        <div className="bg-white rounded p-4 lg:p-6">
          {error && (
            <span className="block text-red-500 text-xs pb-3">{error}</span>
          )}
          <div className="flex">
            <div className="ml-0 w-full">
              <label
                htmlFor="mm"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                MM
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="mm"
                  type="number"
                  className="form-input block w-full sm:text-sm sm:leading-5"
                  placeholder="11"
                  min={1}
                  max={12}
                  value={birthday.mm}
                  onChange={(e) => handleChange('mm', e.target.value)}
                />
              </div>
            </div>
            <div className="ml-2 w-full">
              <label
                htmlFor="dd"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                DD
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="dd"
                  type="number"
                  className="form-input block w-full sm:text-sm sm:leading-5"
                  placeholder="23"
                  value={birthday.dd}
                  min={1}
                  max={31}
                  onChange={(e) => handleChange('dd', e.target.value)}
                />
              </div>
            </div>
            <div className="ml-2 w-full">
              <label
                htmlFor="yyyy"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                YYYY
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="yyyy"
                  type="number"
                  className="form-input block w-full sm:text-sm sm:leading-5"
                  placeholder="1990"
                  value={birthday.yyyy}
                  min={1900}
                  max={2500}
                  onChange={(e) => handleChange('yyyy', e.target.value)}
                />
              </div>
            </div>
          </div>
          <button
            className="btn btn-border btn-round w-full mt-6"
            onClick={updateBirthday}
          >
            {loading ? <i className="icon-loading"></i> : 'Save'}
          </button>
        </div>
      </Modal>
    </div>
  );
}
