import React from 'react';
import PropTypes from 'prop-types';
import ContestPrizeIcon from '../ContestPrizeIcon';
import LazyImg from '../LazyImg';
import EndingSoonBanner from '../EndingSoonBanner';

function ContestTileMedium(props) {
  const { contest } = props;
  return (
    <a href={contest.url} className="inline-block relative w-full h-full bg-white overflow-hidden rounded-sm shadow-sm transition-colors duration-100 hover:text-blue-700">
      <LazyImg src={contest.photo} />
      <EndingSoonBanner endsAt={contest.ends_at} />
      <div className="flex justify-center relative">
        <ContestPrizeIcon icon={contest.prize_icon} className="absolute" style={{top: -27}} />
      </div>
      <p bablic-exclude="true" className="pt-7 px-6 mb-6 box-border md:h-32 leading-5 overflow-hidden break-words">{contest.title}</p>
    </a>
  );
}

ContestTileMedium.propTypes = {
  contest: PropTypes.shape({
    title: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    ends_at: PropTypes.string.isRequired,
    campaign_bands: PropTypes.array.isRequired,
    prize_icon: PropTypes.string
  })
};

export default ContestTileMedium;
