import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import useScrollTop from '../../hooks/useScrollTop';
import Modal from '../../components/Modal';
import TS from '../../api';
import useAsyncState from '../../hooks/useAsyncState';

export default function DeactivateAccount(props) {
  useScrollTop(props);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState('');
  const [loading, setLoading] = useAsyncState(false);
  const [comments, setComments] = useState('');
  const [reasons, setReasons] = useState({
    1: {
      label: "Don't like it",
      value: false
    },
    2: {
      label: "Don't use it",
      value: false
    },
    3: {
      label: 'Not enough artists',
      value: false
    },
    4: {
      label: 'Never win',
      value: false
    },
    5: {
      label: 'To restrict my data',
      value: false
    },
    6: {
      label: 'Other',
      value: false
    }
  });

  const buildReasonsString = () => {
    let ret = [];
    Object.values(reasons).forEach((reason) => {
      if (reason.value) {
        ret.push(reason.label);
      }
    });
    return ret.join(', ').trim();
  };

  const handleChange = (e) => {
    const id = e.target.id;
    const checked = e.target.checked;
    setReasons((prev) => ({
      ...prev,
      [id]: {
        label: prev[id].label,
        value: checked
      }
    }));
  };

  const deleteAccount = () => {
    if (!loading && confirmationInput === 'DELETE') {
      setLoading(true);
      TS.post('/user_delete_requests', {
        user_delete_request: {
          reason: buildReasonsString(),
          comment: comments
        }
      })
        .then(() => {
          setLoading(false);
          window.location.reload();
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div className="mx-auto max-w-screen-lg pt-6 pb-12 px-4 lg:px-0">
      <Link to="/settings/account" className="link-blue text-sm">
        <i className="icon-arrow-left"></i> Back to Account
      </Link>
      <div>
        <header className="mt-3">
          <h1 className="font-normal text-2xl">Delete Account</h1>
          <p className="text-sm">
            Deleting your account is <b>PERMANENT</b>. Access to your data will
            be immediately restricted, and all personal data will be deleted
            within 30 days of your request. You will not be able to log in or
            create an account with the same email.
          </p>
          <p className="text-sm mt-3">
            Please review the{' '}
            <Link to="/settings/my-data" className="link-blue">
              data we've collected
            </Link>{' '}
            and consider{' '}
            <Link to="/settings/deactivate-account" className="link-blue">
              deactivating your account
            </Link>{' '}
            instead.
          </p>
        </header>
        <p className="font-normal text-xl mt-6">
          Please let us know why you're leaving
        </p>
        <div className="flex flex-wrap pt-2">
          {Object.keys(reasons).map((key) => (
            <div className="flex items-center w-1/2 pb-2" key={key}>
              <input
                id={key}
                type="checkbox"
                value={reasons[key].value}
                onChange={handleChange}
                className="form-checkbox h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
              />
              <div className="ml-3 text-sm leading-5">
                <label htmlFor={key} className="font-medium text-gray-700">
                  {reasons[key].label}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="rounded-md shadow-sm">
          <textarea
            id="about"
            rows="3"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            placeholder="Comments"
          ></textarea>
        </div>
        <button
          className="btn btn-border btn-round mt-6 w-full"
          onClick={() => setShowConfirmModal(true)}
        >
          {loading ? <i className="icon-loading"></i> : 'Delete'}
        </button>
      </div>
      <Modal open={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
        <div className="bg-white rounded p-4 lg:p-6">
          <h1 className="font-normal text-2xl">
            Are you sure you wish to delete your account?
          </h1>
          <p className="mt-3">
            You can restrict access to your data by{' '}
            <Link to="/settings/deactivate-account" className="link-blue">
              deactivating your account
            </Link>{' '}
            instead.
          </p>
          <div className="mt-6">
            <label
              htmlFor="delete_confirmation"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Type "DELETE" below to confirm:
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="delete_confirmation"
                className="form-input block w-full sm:text-sm sm:leading-5"
                placeholder="DELETE"
                value={confirmationInput}
                onChange={(e) => setConfirmationInput(e.target.value)}
              />
            </div>
          </div>
          <button
            className={classnames('btn-round w-full mt-3', {
              'btn btn-border': confirmationInput === 'DELETE',
              'btn btn-disabled': confirmationInput !== 'DELETE'
            })}
            onClick={deleteAccount}
          >
            {loading ? <i className="icon-loading"></i> : 'Delete Account'}
          </button>
        </div>
      </Modal>
    </div>
  );
}
