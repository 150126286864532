import React from 'react';
import { Link } from 'react-router-dom';
import FavoriteHeart from '../FavoriteHeart';
import BackgroundImage from '../BackgroundImage';
import ContestChip from '../ContestChip';
import searchPlaceholderMobileImg from '../../assets/images/artist_placeholder.svg';
import searchPlaceholderImg from '../../assets/images/search_placeholder.svg';

function SearchResultTile(props) {
  const { result } = props;
  let desktopPhotoSrc = searchPlaceholderImg;
  let mobilePhotoSrc = searchPlaceholderMobileImg;
  if (!result.band.is_default_photo) {
    desktopPhotoSrc = result.band.photo;
    mobilePhotoSrc = result.band.photo;
  }

  if (result) {
    const activeCampaigns = result.campaigns.filter(c => !c.ended && !c.canceled);

    return (
      <div className="bg-white rounded-sm shadow-sm overflow-hidden flex mt-4 md:mt-6">
        <Link
          to={`artist/${result.band.id}`}
          className="block min-w-16 sm:min-w-24 md:min-w-40 min-h-16 sm:min-h-24 md:min-h-40"
        >
          <BackgroundImage src={desktopPhotoSrc} size="cover" className="w-full h-full" />
        </Link>
        <div className="flex-1 p-2 md:p-4 pl-3 md:pl-6">
          <div className="flex justify-between mb-2 md:mb-4">
            <Link to={`artist/${result.band.id}`} className="link-blue font-light md:text-xl lg:text-2xl">
              <p bablic-exclude="true">{result.band.name}</p>
            </Link>
            <FavoriteHeart bandPage={result} />
          </div>
          <div className="divider-fade-right mb-2 md:mb-4" />
            {
              activeCampaigns.length > 0 &&
              <ContestChip label={activeCampaigns[0].title} url={activeCampaigns[0].url} icon={activeCampaigns[0].prize_icon} />
            }
        </div>
      </div>
    )
  }
  return null;
}

export default SearchResultTile;
