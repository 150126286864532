import React, { useState, useEffect, Fragment } from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import history from '../../history';
import Account from './Account';
import ArtistMessages from './ArtistMessages';
import Notifications from './Notifications';
import Services from './Services';
import MyData from './MyData';
import DeactivateAccount from './DeactivateAccount';
import DeleteAccount from './DeleteAccount';
import EmailVerified from './EmailVerified';
import ProtectedRoute from '../../components/ProtectedRoute';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import FlashNotification from '../../components/FlashNotification';
import useSearchParams from '../../hooks/useSearchParams';
import useAsyncState from '../../hooks/useAsyncState';
import { ReactComponent as CloseOutlineIcon } from '../../assets/images/close-outline.svg';

export default function Settings(props) {
  const search = useSearchParams();
  const [error] = useState(search.error);
  const [showErrorNotification, setShowErrorNotification] = useAsyncState(false);

  const navLinks = [
    { to: '/settings/account', label: 'Account' },
    { to: '/settings/notifications', label: 'Notifications' },
    { to: '/settings/connected-services', label: 'Apps' },
    { to: '/settings/my-data', label: 'My Data' }
  ];

  useEffect(() => {
    if (error) {
      delete search.error;
      history.replace(search);
      if (error?.toLowerCase()?.includes('authorization error')) {
        setShowErrorNotification(true);
      }
    }
  }, [error]);

  return (
    <Fragment>
      <div className="min-h-screen bg-gray-200 relative">
        <div className="bg-blue-gradient">
          <Navbar className="text-white" />

          <div className="max-w-screen-lg mx-auto w-full px-4 lg:px-0">
            <p className="text-3xl font-thin text-white mt-6">Settings</p>
            {navLinks.map(({ to, label }) => (
              <NavLink
                key={to}
                to={to}
                className="inline-block py-2 mr-4 text-white text-sm sm:text-base sm:mr-6"
                activeClassName="border-b-4 border-white border-solid font-medium"
              >
                {label}
              </NavLink>
            ))}
          </div>

          <FlashNotification
            isOpen={showErrorNotification}
            timeout={5000}
            handleClose={() => setShowErrorNotification(false)}
          >
            <div className="flex items-center">
              <div className="text-red-500">
                <CloseOutlineIcon className="h-6 w-6 fill-current" />
              </div>
              <p className="ml-3 capitalize">{error}</p>
            </div>
          </FlashNotification>
        </div>

        <Switch>
          <ProtectedRoute
            exact
            path="/settings/account"
            component={Account}
          />
          <ProtectedRoute
            exact
            path="/settings/notifications"
            component={Notifications}
          />
          <ProtectedRoute
            exact
            path="/settings/connected-services"
            component={Services}
          />
          <ProtectedRoute
            exact
            path="/settings/my-data"
            component={MyData}
          />

          <ProtectedRoute
            exact
            path="/settings/artist-messages"
            component={ArtistMessages}
          />

          <ProtectedRoute
            exact
            path="/settings/deactivate-account"
            component={DeactivateAccount}
          />

          <ProtectedRoute
            exact
            path="/settings/delete-account"
            component={DeleteAccount}
          />

          <Route
            exact
            path="/settings/email-verified"
            component={EmailVerified}
          />

          <Redirect to="/settings/account" />
        </Switch>
      </div>
      <Footer />
    </Fragment>
  );
}
