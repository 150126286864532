import { Component } from 'react';
import { Notifier } from '@airbrake/browser';
import constants from '../../constants';

const airbrake = new Notifier({
  projectId: constants.KEYS.airbrake.projectId,
  projectKey: constants.KEYS.airbrake.projectKey
});

class ErrorBoundary extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = { hasError: false };
  // }

  componentDidCatch(error, info) {
    if (constants.env === 'development') return;
    airbrake.notify({
      error: error,
      context: {
        component: this.props.component
      },
      params: { info }
    });
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return <h1>Something went wrong.</h1>;
    // }
    return this.props.children;
  }
}

export default ErrorBoundary;
