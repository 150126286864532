import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as Placeholder } from '../../assets/images/contest_tile_placeholder.svg';

function LazyImg(props) {
  const { src, alt, onLoad, options, className } = props;
  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef();
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        imgRef.current.onload = () => {
          setLoaded(true);
          if (onLoad) {
            onLoad();
          }
        }
        imgRef.current.src = src;
        observer.disconnect();
      }
    }, options);
    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [containerRef]);

  return (
    <div className={classnames('relative overflow-hidden max-w-full', className)} ref={containerRef}>
      <Placeholder className="relative h-full w-full"/>
      <img
        alt={alt}
        className={classnames('absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-200', {
          'opacity-100': loaded
        })}
        ref={imgRef}
      />
    </div>
  );
}

LazyImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onLoad: PropTypes.func,
  options: PropTypes.shape({
    root: PropTypes.node,
    rootMargin: PropTypes.string,
    threshold: PropTypes.number
  })
}

LazyImg.defaultProps = {
  alt: "",
  onLoad: null,
  options: {
    threshold: 0.5
  }
}

export default LazyImg;