import React from 'react';
import PropTypes from 'prop-types';
import PrizeIcon from '../PrizeIcon';
import classnames from 'classnames';

function ContestPrizeIcon(props) {
  const { icon, className, style } = props;
  return (
    <div style={style} className={classnames('border border-white absolute rounded-full', className)}>
      <div className="rounded-full bg-white flex items-center justify-center h-12 w-12 p-2 m-1 overflow-hidden">
        <PrizeIcon icon={icon} />
      </div>
    </div>
  );
}

ContestPrizeIcon.propTypes = {
  icon: PropTypes.string
}

export default ContestPrizeIcon;


