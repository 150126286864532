import React, { useEffect, useMemo, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { DateTime } from 'luxon';
import history from '../../history';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import LoadingPage from '../../components/LoadingPage';
import PresaleListItem from '../../components/PresaleListItem';
import RequiresPassword from '../../components/RequiresPassword';
import useRequest from '../../hooks/useRequest';
import useScrollTop from '../../hooks/useScrollTop';

const groupPresalesByDate = function (presaleGroup) {
  const ret = {
    active: [],
    ended: [],
  };
  if (presaleGroup) {
    presaleGroup.presales.forEach((presale) => {
      if (DateTime.fromISO(presale.show_starts_at).diffNow() > 0) {
        ret.active.push(presale);
      } else {
        ret.ended.push(presale);
      }
    });
  }
  return ret;
};

function PresaleGroup(props) {
  useScrollTop(props);
  const id = props.match.params.id;
  const [req] = useRequest({
    type: 'get',
    path: `/presales/${id}`,
    serializer: (res) => res.presale_group
  });

  useEffect(() => {
    if (req.errors) {
      // 403 handled by RequiresPassword
      if (req?.errors?.status !== 403) {
        history.replace('/404');
      }
    }
  }, [req.errors]);

  const presales = useMemo(() => groupPresalesByDate(req.data), [req.data]);

  if (req.data) {
    const { title, subtitle, og_title, og_description, facebook_post_photo, theme_css, background_photo, header_photo } = req.data;

    return (
      <Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <title bablic-exclude="true">{title} - Tunespeak</title>
          <meta property="og:title" bablic-exclude="true" content={og_title} />
          {og_description && <meta property="og:description" bablic-exclude="true" content={og_description} />}
          <meta property="og:image" content={facebook_post_photo} />

          <meta name="twitter:title" bablic-exclude="true" content={og_title} />
          <meta name="twitter:image" content={og_description} />
          {og_description && <meta name="twitter:description" bablic-exclude="true" content={facebook_post_photo} />}
          {theme_css && <style>{theme_css}</style>}
        </Helmet>

        <div className="PresaleGroup__bg relative bg-black min-h-screen overflow-auto">
          {background_photo && <div className="PresaleGroup__bg-photo z-10 fixed inset-0 w-full h-full bg-no-repeat bg-cover bg-center" style={{backgroundImage: `url(${background_photo})`}} />}
          <Navbar className="relative text-white z-20" />
          <div className="PresaleGroup bg-gray-100 sm:rounded overflow-hidden relative max-w-screen-md mx-auto sm:mt-10 sm:mb-20 z-20" bablic-exclude="true">
            {header_photo && <img src={header_photo} alt="" className="PresaleGroup__photo" />}
            <div className="px-4 pt-4 sm:px-6 sm:pt-6">
              {title && <h2 className="PresaleGroup__title sm:text-center tssdk-h2">{title}</h2>}
              {subtitle && <p className="PresaleGroup__subtitle tssdk-p">{subtitle}</p>}
              <ul className="mt-6">
                {
                  presales.active.map((presale) => {
                    return (
                      <PresaleListItem key={presale.id} presale={presale} />
                    );
                  })
                }
              </ul>
              {presales.ended.length && (
                <Fragment>
                  <div className="font-bold mt-6">Past:</div>
                  <ul>
                    {
                      presales.ended.map((presale) => {
                        return (
                          <PresaleListItem key={presale.id} presale={presale} disabled />
                        );
                      })
                    }
                  </ul>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }

  return (
    <RequiresPassword errors={req.errors} cookie="presale_pwd">
      <LoadingPage />
    </RequiresPassword>
  );
}

export default PresaleGroup;