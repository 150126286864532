import React from 'react';
import classnames from 'classnames'
import PropTypes from 'prop-types';

function LineDivider(props) {
  const { variant, left, right, className } = props;
  const leftContent = left && <div className="pr-3 md:pr-6 flex-shrink-0">{left}</div>;
  const rightContent = right && <div className="pl-3 md:pl-6 flex-shrink-0">{right}</div>;

  return (
    <div className={classnames('flex items-center w-full text-xs md:text-sm', className)}>
      {leftContent}
      <div className={`bg-black h-px flex-grow ${variant === 'fade-right' ? 'divider-fade-right' : ''}`} />
      {rightContent}
    </div>
  );
}

LineDivider.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  variant: PropTypes.string,
};

LineDivider.defaultProps = {
  left: null,
  right: null,
  variant: 'default',
};

export default LineDivider;
