import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

import Loading from '../Loading';

function AwsMarkdown(props) {
  const { url, bucket, file } = props;
  const [markdown, setMarkdown] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchFile = async (url) => {
    if (url) {
      setLoading(true);
      const md = await axios({
        method: 'get',
        url: url,
        withCredentials: false
      });
      setLoading(false);
      setMarkdown(md.data);
    }
  }

  useEffect(() => {
    if (url) {
      fetchFile(url);
    } else if (bucket && file) {
      const url = `https://s3.amazonaws.com/${bucket}/${file}`;
      fetchFile(url);
    }
  }, [url, bucket, file]);

  if (loading) {
    return <Loading />
  }

  return (
    <ReactMarkdown 
      children={markdown} 
      remarkPlugins={[remarkGfm]}
      skipHtml={false}
      className="AwsMarkdown"
    />
  );
}

export default AwsMarkdown;