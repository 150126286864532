import React, { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet';
import useScrollTop from '../../hooks/useScrollTop';
import { browserLanguage } from '../../components/Localization';
import AuthContext from '../../contexts/AuthContext';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import AwsMarkdown from '../../components/AwsMarkdown';

const FILES = {
  fr: 'tos.fr.md',
  de: 'tos.de.md',
  es: 'tos.es.md',
  en: 'tos.md'
};

function Terms(props) {
  useScrollTop(props);
  const { session } = useContext(AuthContext);
  const locale = session?.data?.profile?.locale;
  const file = FILES[locale || browserLanguage] || FILES['en'];
  
  return (
    <Fragment>
      <Helmet>
        <title>Tunespeak / Terms of Service</title>
      </Helmet>
      <div className="bg-gray-100 min-h-screen">
        <Navbar className="bg-blue-gradient text-white"/>
        <div className="max-w-screen-lg mx-auto py-10 px-6" bablic-exclude="true">
          <AwsMarkdown bucket="tunespeak-cms" file={file} />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Terms;