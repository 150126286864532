import { useEffect } from 'react';
import useAsyncState from './useAsyncState';
import TS from '../api';

const OPTIONS = {
  type: 'get',
  path: '',
  base: '',
  body: null,
  serializer: null
};

const useRequest = (requestOptions = {}) => {
  const [options, setOptions] = useAsyncState({
    type: requestOptions.type || OPTIONS.type,
    path: requestOptions.path || OPTIONS.path,
    base: requestOptions.base || OPTIONS.base,
    body: requestOptions.body || OPTIONS.body,
    serializer: requestOptions.serializer || OPTIONS.serializer,
  });
  
  const [value, setValue] = useAsyncState({
    loading: false,
    data: null,
    errors: null,
    complete: false,
    success: false
  });

  useEffect(() => {
    if (options.path || options.base) {
      setValue({ ...value, loading: true, errors: null, complete: false });
      TS.request(options)
        .then((res) => {
          if (typeof options.serializer === 'function') {
            const serialized = options.serializer(res);
            setValue({
              loading: false,
              data: serialized,
              errors: null,
              complete: true,
              success: true
            });
          } else {
            setValue({
              loading: false,
              data: res,
              errors: null,
              complete: true,
              success: true
            });
          }
        })
        .catch((error) => {
          setValue({
            loading: false,
            data: null,
            errors: error,
            complete: true,
            success: false
          });
        });
    }
  }, [options]);

  // returns array with function to trigger request by setting a new url and the request values
  // value -> the promise response with data
  // setOptions -> function to re-run request with new options
  // setValue -> function to manually edit the response
  return [value, setOptions, setValue];
};

export default useRequest;
