import React, { Fragment, useEffect, useState } from 'react';
import { normalize } from 'normalizr';
import { DateTime } from 'luxon';
import LineDivider from '../../components/LineDivider';
import NoContent from '../../components/NoContent';
import Toggle from '../../components/Toggle';
import useRequest from '../../hooks/useRequest';
import useScrollTop from '../../hooks/useScrollTop';
import userDataExportsModel from '../../models/userDataExports';
import userConsentsModel from '../../models/userConsents';
import TS from '../../api';
import { dateInPast } from '../../utils/utilities';
import useAsyncState from '../../hooks/useAsyncState';

let interval;

const UserDataExport = (props) => {
  const { data } = props;
  const right = (() => {
    if (dateInPast(data.expires_at)) {
      return <p className="text-gray-600 italic">Expired</p>;
    } else {
      if (data.download_url) {
        if (data.file_format === 'html') {
          return (
            <a href={data.download_url} target="_blank" className="link-blue">
              View <i className="icon-leave"></i>
            </a>
          );
        } else {
          return (
            <a href={data.download_url} target="_blank" className="link-blue">
              Download <i className="icon-download"></i>
            </a>
          );
        }
      } else {
        return (
          <p className="text-gray-600 italic pl-3">
            Working <i className="icon-loading"></i>
          </p>
        );
      }
    }
  })();

  return (
    <div className="p-3 sm:px-4 border-b">
      <div className="flex">
        <div className="w-full">
          <p bablic-exclude="true" className="leading-5">
            {DateTime.fromISO(data.created_at, { zone: 'utc' }).toFormat(
              'MM/dd/yy - hh:mm a'
            )}
          </p>
          <p className="text-xs text-gray-600">
            {data.file_format === 'html'
              ? 'Human Readable'
              : 'Machine Readable'}
          </p>
        </div>
        <div className="flex items-center flex-shrink-0">{right}</div>
      </div>
    </div>
  );
};

export default function MyData(props) {
  useScrollTop(props);

  const [error, setError] = useState(null);
  const [polling, setPolling] = useState(false);
  const [userExports, setUserExports] = useAsyncState({});
  const [userExportsReq, fetchUserExports] = useRequest({
    type: 'get',
    path: '/user_data_exports',
    serializer: (res) => {
      return normalize(res.user_data_exports, userDataExportsModel).entities;
    }
  });

  const [userConsents, setUserConsents] = useAsyncState({});
  const [userConsentsReq] = useRequest({
    type: 'get',
    path: '/user_consents',
    serializer: (res) => {
      return normalize(res.user_consents, userConsentsModel).entities;
    }
  });

  const createExport = (format = 'html') => {
    TS.post('/user_data_exports', {
      user_data_export: {
        file_format: format
      }
    })
      .then(() => {
        setPolling(true);
      })
      .catch(() => {
        setError('* Limit 1 Export Per Hour');
      });
  };

  const toggleConsent = (id, value) => {
    TS.put(`/user_consents/${id}`, {
      user_consent: {
        consented: value
      }
    });
  };

  // Poll for data export updates
  useEffect(() => {
    if (polling) {
      const options = {
        type: 'get',
        path: '/user_data_exports'
      };
      fetchUserExports(options);
      interval = setInterval(() => {
        fetchUserExports(options);
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [polling]);

  useEffect(() => {
    if (userExportsReq?.data?.user_data_exports) {
      setUserExports(userExportsReq.data.user_data_exports);
    }
  }, [userExportsReq?.data?.user_data_exports]);

  useEffect(() => {
    if (userConsentsReq?.data?.user_consents) {
      setUserConsents(userConsentsReq.data.user_consents);
    }
  }, [userConsentsReq?.data?.user_consents]);

  return (
    <div className="mx-auto max-w-screen-lg pt-6 pb-12 px-4 lg:px-0">
      {/* EXPORT DATA */}
      <section>
        <header>
          <h1 className="font-normal text-2xl">Export Data</h1>
          <p className="text-sm">
            If you would like to view and export the data we collect from your
            use of Tunespeak, please select a format below to start a request.
            Once your data is ready to view or download, we will send you an
            email letting you know.
          </p>
        </header>
        <p className="text-red-500 text-sm mt-6">{error}</p>
        <div className="flex flex-col sm:flex-row bg-white overflow-hidden rounded-sm shadow-sm p-3 sm:p-4 mt-6">
          <div className="sm:w-3/5">
            <p className="leading-5">
              Human Readable
            </p>
            <div className="divider-fade-right my-2"></div>
            <p className="text-xs text-gray-600">
              Generate a simple human-readable HTML file viewable in any web
              browser.
            </p>
          </div>
          <div className="pt-4 sm:pt-0 sm:w-2/5 flex justify-end items-center">
            <button
              className="btn btn-border btn-round w-full sm:w-auto"
              onClick={() => createExport('html')}
            >
              Request Data
            </button>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row bg-white overflow-hidden rounded-sm shadow-sm p-3 sm:p-4 mt-6">
          <div className="sm:w-3/5">
            <p className="leading-5">
              Machine Readable
            </p>
            <div className="divider-fade-right my-2"></div>
            <p className="text-xs text-gray-600">
              Generate a JSON document to export your data to another format or
              service.
            </p>
          </div>
          <div className="pt-4 sm:pt-0 sm:w-2/5 flex justify-end items-center">
            <button
              className="btn btn-border btn-round w-full sm:w-auto"
              onClick={() => createExport('json')}
            >
              Request Data
            </button>
          </div>
        </div>

        {/* RECENT EXPORTS */}
        {userExportsReq.success && !!Object.keys(userExports).length && (
          <Fragment>
            <h2 className="font-normal text-gray-800 text-xl my-6">
              Recent Exports
            </h2>

            <div className="bg-white overflow-hidden rounded-sm shadow-sm">
              {Object.keys(userExports).map((key) => (
                <UserDataExport data={userExports[key]} key={key} />
              ))}
            </div>
          </Fragment>
        )}
      </section>

      {/* RECENT CONSENTS */}
      <section className="mt-10">
        <header>
          <h1 className="font-normal text-2xl">Consents</h1>
          This section may only apply if you or your activity are subject to
          GDPR regulations and a campaign prompted you to explicitly allow
          sharing data with an artist or campaign sponsor.
        </header>

        <div className="bg-white overflow-hidden rounded-sm shadow-sm mt-6">
          {userConsentsReq.success && !Object.keys(userConsents).length && (
            <NoContent className="text-gray-600 text-sm">
              You haven't responded to any consent prompts.
            </NoContent>
          )}
          {userConsentsReq.success &&
            Object.keys(userConsents).map((key) => (
              <div className="p-3 sm:px-4 border-b" key={userConsents[key].id}>
                <div className="flex">
                  <div className="w-full">
                    <p bablic-exclude="true" className="leading-5">
                      {userConsents[key].consent.grantee_name}
                    </p>
                    <p bablic-exclude="true" className="text-xs text-gray-600">
                      {userConsents[key].consent.description}
                    </p>
                  </div>
                  <div className="flex items-center pl-3 flex-shrink-0">
                    <Toggle
                      initial={userConsents[key].consented}
                      callback={(value) =>
                        toggleConsent(userConsents[key].id, value)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
}
