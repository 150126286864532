import React, { useState, Fragment, useContext } from 'react';
import classnames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import constants from '../../constants';
import Drawer from '../Drawer';
import BackgroundImage from '../BackgroundImage';
import AuthContext from '../../contexts/AuthContext';
import TS from '../../api';
import { ReactComponent as TunespeakLogo } from '../../assets/images/tunespeak_logo.svg';
import { ReactComponent as IconLogout } from '../../assets/images/icon_logout.svg';
import { ReactComponent as IconSettings } from '../../assets/images/icon_settings.svg';
import { ReactComponent as IconCampaign } from '../../assets/images/icon_campaign.svg';
import { ReactComponent as IconInbox } from '../../assets/images/icon_inbox.svg';

export function Navbar(props) {
  const { session, update: updateSession } = useContext(AuthContext);
  const { className, showLogo = true } = props;
  const loginURL = `${constants.EMBER_APP_URL}/login?redirect=${encodeURIComponent(window.location.href.toString())}`;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const menuOpen = () => {
    setDrawerOpen(true);
  }
  const menuClose = () => {
    setDrawerOpen(false);
  }
  const logout = async (e) => {
    if (e) {
      e.preventDefault();
    }
    TS.delete('/sessions').then(() => {
      menuClose();
      updateSession({
        loading: false,
        data: null,
        errors: null,
        complete: true,
        success: false
      });
    });
  }

  const avatar = (() => {
    if (session.loading) {
      return (
        <div className="block h-8 w-8 rounded-full">
          <i className="icon-loading text-xl"></i>
        </div>
      );
    } else if (session.success) {
      return (
        <div className="menu-toggle cursor-pointer flex items-center" onClick={menuOpen}>
          <i className="icon-menu mr-2 text-xl"></i>
          <BackgroundImage className="inline-block h-8 w-8 rounded-full" src={session.data.photo} />
        </div>
      );
    }

    return (
      <div className="menu-toggle cursor-pointer" onClick={menuOpen}>
        <i className="icon-menu text-xl"></i>
      </div>
    );
  })();

  return (
    <Fragment>
      <Drawer open={drawerOpen} onClose={menuClose}>
        <div className="flex items-center justify-between flex-shrink-0 px-4 py-6">
          <Link
            to="/"
            className="link-blue text-center"
            onMouseDown={menuClose}
          >
            <TunespeakLogo className="h-4" />
          </Link>
          <i className="icon-x cursor-pointer text-xl text-gray-400 hover:text-blue-700" onClick={menuClose} />
        </div>
        <div className="flex-grow flex flex-col">
          <nav className="flex-1 bg-white">
            { !session.success &&
              <a href={loginURL} className="group flex items-center px-3 py-5 leading-5 font-medium text-gray-600 border-l-4 border-transparent hover:text-blue-700 hover:bg-gray-100 focus:outline-none focus:text-blue-700 focus:bg-gray-100 transition ease-in-out duration-150">
                <i className="icon-power text-xl mr-3"></i>
                Login
              </a>
            }
            { session.success &&
              <Fragment>
                <NavLink
                  to="/campaigns"
                  className="group flex items-center px-3 py-5 leading-5 font-medium text-gray-600 border-l-4 border-transparent hover:text-blue-700 hover:bg-gray-100 focus:outline-none focus:text-blue-700 focus:bg-gray-100 transition ease-in-out duration-150"
                  activeClassName="border-blue-700 bg-gray-50 link-blue"
                  onClick={menuClose}
                >
                  <IconCampaign className="h-5 mr-3" />
                  My Campaigns
                </NavLink>
                <a href={`${constants.EMBER_APP_URL}/inbox`} className="group flex items-center px-3 py-5 leading-5 font-medium text-gray-600 border-l-4 border-transparent hover:text-blue-700 hover:bg-gray-100 focus:outline-none focus:text-blue-700 focus:bg-gray-100 transition ease-in-out duration-150">
                  <IconInbox className="h-5 mr-3" />
                  Inbox
                </a>
                <Link to="/settings" className="group flex items-center px-3 py-5 leading-5 font-medium text-gray-600 border-l-4 border-transparent hover:text-blue-700 hover:bg-gray-100 focus:outline-none focus:text-blue-700 focus:bg-gray-100 transition ease-in-out duration-150">
                  <IconSettings className="h-5 mr-3" />
                  Settings
                </Link>
                <a href="/" onClick={logout} className="group flex items-center px-3 py-5 leading-5 font-medium text-gray-600 border-l-4 border-transparent hover:text-blue-700 hover:bg-gray-100 focus:outline-none focus:text-blue-700 focus:bg-gray-100 transition ease-in-out duration-150">
                  <IconLogout className="h-5 mr-3" />
                  Logout
                </a>
              </Fragment>
            }
          </nav>
        </div>
      </Drawer>

      <div className={classnames('Navbar px-4 pl-3', className)}>
        <div className="flex justify-between items-center py-4 justify-start space-x-5">
          <div className="w-0 flex-1 flex justify-start">
            {avatar}
          </div>
          <nav className="flex space-x-10">
            {showLogo && (
              <div className="relative">
                <Link to="/">
                  <TunespeakLogo className="h-4 sm:h-5" />
                </Link>
              </div>
            )}
          </nav>
          <div className="w-0 flex-1">
            <div className="invisible sm:visible text-xl flex items-center justify-end space-x-3">
              <a href="https://twitter.com/tunespeak" target="_blank" rel="noopener noreferrer"><i className="icon-twitter"></i></a>
              <a href="http://tunespeakapp.tumblr.com/" target="_blank" rel="noopener noreferrer"><i className="icon-tumblr"></i></a>
              <a href="https://www.instagram.com/tunespeak/" target="_blank" rel="noopener noreferrer"><i className="icon-instagram"></i></a>
              <a href="https://www.facebook.com/tunespeak/" target="_blank" rel="noopener noreferrer"><i className="icon-facebook"></i></a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Navbar;
