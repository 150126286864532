import React, { useEffect, useState, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import classnames from 'classnames';
import TimeAgo from 'react-timeago';
import ContestPrizeIcon from '../../components/ContestPrizeIcon';
import BackgroundImage from '../../components/BackgroundImage';
import LineDivider from '../../components/LineDivider';
import Loading from '../../components/Loading';
import NoContent from '../../components/NoContent';
import TS from '../../api';
import constants from '../../constants';
import { ReactComponent as TicketIcon } from '../../assets/images/icon_ticket.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/icon_clock.svg';
import { commafyNumber } from '../../utils/utilities';
import placeholderImg from '../../assets/images/campaign_placeholder.svg';
import pluralize from 'pluralize';

export default function Ongoing({ active = true }) {
  const [data, setData] = useState({
    user_contests: [],
    next_url: `${constants.API_ROOT}/user_contests?active=${active}`,
    has_more: true
  });

  const fetchCampaigns = () => {
    TS.get(data.next_url)
      .then((res) => {
        setData((prevData) => ({
          user_contests: [...prevData.user_contests, ...res.user_contests],
          next_url: `${res.meta.scroll.next_url}&active=${active}`,
          has_more: res.meta.scroll.results_count > 0
        }));
      })
      .catch(() => {
        setData((prevData) => ({
          ...prevData,
          has_more: false
        }));
      });
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <div className="px-4 py-6 lg:px-0 lg:py-8 flex flex-col flex-grow items-center">
      <div className="max-w-screen-lg w-full">
        {data.user_contests.length === 0 && data.has_more === false && (
          <NoContent>No campaigns found.</NoContent>
        )}
        <InfiniteScroll
          dataLength={data.user_contests.length}
          next={fetchCampaigns}
          hasMore={data.has_more}
          loader={<Loading className="flex flex-grow justify-center" />}
          className="min-w-full space-y-6 md:space-y-8"
        >
          {data.user_contests.map((uc) => {
            const { points } = uc;
            const { url, thumbnail, prize_icon, title, ended, ends_at, canceled } = uc.contest_preview;
            return (
              <a
                href={url}
                className="flex flex-col sm:flex-row relative bg-white overflow-hidden rounded-sm shadow-sm transition-colors duration-100 hover:text-blue-700"
                key={uc.id}
              >
                {/* Mobile image + icon */}
                <div className="sm:hidden">
                  <img src={thumbnail || placeholderImg} className="w-full min-h-20 bg-black" />
                  <div className="relative flex justify-center">
                    <ContestPrizeIcon icon={prize_icon} className="absolute" style={{top: -30}} />
                  </div>
                </div>

                {/* Desktop image + icon */}
                <div className="hidden sm:block w-1/4 min-h-full p-1 box-border">
                  <BackgroundImage src={thumbnail || placeholderImg} size="cover" className="w-full h-full" />
                </div>
                <div className="hidden sm:flex relative flex-col justify-center min-h-full">
                  <ContestPrizeIcon icon={prize_icon} className="absolute -ml-8" />
                </div>
                
                <div className="p-4 pt-6 sm:px-8 min-w-64 flex-1">
                  <p bablic-exclude="true" className="leading-5">{title}</p>
                  <div className="divider-fade-right my-4"></div>
                  <div className="text-xs text-gray-500 flex items-center">
                    {points > 0 && 
                      <Fragment>
                        <TicketIcon className="h-4 w-4 mr-1 fill-current" /> {commafyNumber(points)} {pluralize('entry', points)}
                        <span className="mx-2">|</span>
                      </Fragment>
                    }
                    {canceled ?
                      <Fragment>
                        <i className="icon-not-allowed h-3 w-3 mr-1" />
                        CANCELED
                      </Fragment>
                      :
                      <Fragment>
                        <ClockIcon className="h-3 w-3 mr-2" />
                        <span className="hidden sm:inline">
                          {ended ? 'Ended' : 'Ends'}&nbsp;
                        </span>
                        <TimeAgo date={ends_at} />
                      </Fragment>
                    }
                  </div>
                </div>
              </a>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
}
