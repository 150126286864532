import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Select from "react-select";
import { Formik, Field, Form, useField, ErrorMessage } from "formik";
import * as yup from "yup";
import { DateTime } from "luxon";
import { commafyNumber } from "../utils/utilities";
import pluralize from "pluralize";
import PhoneInput from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";
import AuthContext from "../contexts/AuthContext";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import FlashNotification from "../components/FlashNotification";
import useRequest from "../hooks/useRequest";
import useScrollTop from "../hooks/useScrollTop";
import TS from "../api";
import constants from "../constants";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ReactComponent as CloseOutlineIcon } from "../assets/images/close-outline.svg";
import history from "../history";
import "react-phone-number-input/style.css";

const actionsFilter = (action) => {
  const excluded_action_templates = [
    "share",
    "instagram-album-look",
    "spotify-album-play",
    "soundcloud-album-play",
  ];
  return (
    action.write_inable === true &&
    (action.point_value === 0 || !excluded_action_templates.includes(action.template))
  );
};

const WriteInSchema = yup.object().shape({
  first_name: yup.string().required("First Name Required"),
  last_name: yup.string().required("Last Name Required"),
  phone: yup.string().required("Mobile Number Required"),
  address_1: yup.string().required("Address Line 1 Required"),
  city: yup.string().required("City Required"),
  postal_code: yup.string().required("Postal Code Required"),
  country: yup.string().required("Country Required"),
  contest_action_id: yup.string().required("Contest Action Required"),
  recaptcha_token: yup.string().required("Recaptcha Required"),
});

function WriteIn(props) {
  useScrollTop(props);
  const id = props.match.params.id;
  const { session } = useContext(AuthContext);
  const loginURL = `${
    constants.EMBER_APP_URL
  }/login?redirect=${encodeURIComponent(window.location.href.toString())}`;
  const enrollUrl = `${constants.EMBER_APP_URL}/campaign/${id}?enroll=true&force=true`;

  const [campaignReq] = useRequest({
    type: "get",
    path: `/campaigns/${id}`,
    serializer: (res) => res.campaign,
  });
  const campaign = campaignReq?.data || {};
  const creditName = useMemo(() => {
    return campaignReq?.data?.type === "Raffle" ? "entry" : "point";
  }, [campaignReq.data]);
  const [userContestReq] = useRequest({
    type: "get",
    path: `/user_contests/${id}`,
    serializer: (res) => res.user_contest,
  });
  const userContest = userContestReq?.data || {};
  const [loading, setLoading] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = async (values, { setFieldError }) => {
    try {
      setLoading(true);
      const res = await TS.post("/write_in_submissions", {
        write_in_submission: {
          contest_id: id,
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          address_1: values.address_1,
          address_2: values.address_2,
          city: values.city,
          state: values.state,
          postal_code: values.postal_code,
          country: values.country,
          contest_action_id: values.contest_action_id,
          recaptcha_token: values.recaptcha_token,
        },
      });
      setLoading(false);
      setShowSuccess(true);
    } catch (e) {
      setShowErrorMsg(e?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (campaignReq.errors?.data?.status === 404) {
      history.push(`/404`);
    }
  }, [campaignReq.errors]);

  return (
    <Fragment>
      <div className="flex flex-col min-h-screen bg-gray-200 relative">
        <div className="bg-blue-gradient">
          <Navbar className="text-white" />
        </div>

        <div className="mx-auto max-w-screen-md w-full flex flex-col grow">
          {(campaignReq.loading || userContestReq.loading) && (
            <div>
              <Loading className="my-20" />
            </div>
          )}

          {showSuccess && (
            <div>
              <img src={campaign?.photo} />
              <div className="p-3 md:p-6 text-center">
                <div>
                  <h2 className="text-xl font-bold">Thanks for submitting your request.</h2>
                  <p>Eligible {pluralize(creditName)} will be added to your account shortly.</p>
                </div>
              </div>
            </div>
          )}

          {!campaignReq.loading &&
            !userContestReq.loading &&
            !campaignReq.errors &&
            !showSuccess && (
              <div>
                <img src={campaign?.photo} />
                <div className="p-3 md:p-6">
                  <h2 className="capitalize text-lg font-semibold">
                    Request Write-In {pluralize(creditName)}
                  </h2>
                  <div className="capitalize">{campaign.title}</div>

                  {/* Campaign Ended */}
                  {campaign.ended && (
                    <div>
                      <div className="w-full border-b border-gray-300 my-6"></div>
                      <div>This campaign has ended.</div>
                    </div>
                  )}

                  {/* Not Logged In */}
                  {!campaign.ended && !session?.data?.id && (
                    <div>
                      <div className="w-full border-b border-gray-300 my-6"></div>
                      <div className=" pt-3">
                        In order to be eligible for write-in credit you must
                        first login or create an account, then enroll in the
                        contest by clicking the{" "}
                        {campaign?.enrollment_lock
                          ? '"GET STARTED"'
                          : '"ENTER TO WIN"'}{" "}
                        button on the contest{" "}
                        <a
                          href={`${constants.EMBER_APP_URL}/campaign/${id}`}
                          target="_blank"
                          className="link-blue"
                        >
                          here
                        </a>
                        .
                      </div>
                      <a
                        href={loginURL}
                        className="btn-primary rounded uppercase mt-6 w-full text-white"
                      >
                        Login
                      </a>
                    </div>
                  )}

                  {/* Not Enrolled */}
                  {!campaign.ended && session?.data?.id && !userContest?.id && (
                    <div>
                      <div className="w-full border-b border-gray-300 my-6"></div>
                      <div className=" pt-3">
                        In order to be eligible for write-in credit you must
                        first login or create an account, then enroll in the
                        contest by clicking the{" "}
                        {campaign?.enrollment_lock
                          ? '"GET STARTED"'
                          : '"ENTER TO WIN"'}{" "}
                        button on the contest{" "}
                        <a
                          href={`${constants.EMBER_APP_URL}/campaign/${id}`}
                          target="_blank"
                          className="link-blue"
                        >
                          here
                        </a>
                        .
                      </div>
                      <a
                        href={enrollUrl}
                        target="_blank"
                        className="btn-primary rounded uppercase mt-6 w-full text-white"
                      >
                        Enroll
                      </a>
                    </div>
                  )}

                  {/* Logged In & Enrolled */}
                  {!campaign.ended && session?.data?.id && userContest?.id && (
                    <div>
                      <div className="text-xs py-3">
                        You may only earn a number of {pluralize(creditName)} up to the limit on
                        the action through any combination of write-ins and
                        standard entry on contest page. All write-ins must be
                        received by{" "}
                        {DateTime.fromISO(campaign.ends_at, {
                          zone: "utc",
                        }).toFormat("hh:mm a z")}{" "}
                        on{" "}
                        {DateTime.fromISO(campaign.ends_at, {
                          zone: "utc",
                        }).toFormat("MM/dd/yy")}
                        . Read the{" "}
                        <Link
                          target="_blank"
                          className="link-blue"
                          to={`/rules/${id}`}
                        >
                          OFFICIAL RULES
                        </Link>{" "}
                        for more information.
                      </div>

                      <Formik
                        initialValues={{
                          first_name: "",
                          last_name: "",
                          phone: "",
                          address_1: "",
                          address_2: "",
                          city: "",
                          postal_code: "",
                          country: "",
                          state: "",
                          contest_action_id: "",
                          recaptcha_token: "",
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={WriteInSchema}
                      >
                        {({ dirty }) => (
                          <Form className="space-y-4">
                            <div>
                              <label
                                htmlFor="contest_action_id"
                                className="block text-sm text-gray-500 mb-1"
                              >
                                Select the action for write-in credit
                              </label>
                              <FormikSelectField
                                id="contest_action_id"
                                name="contest_action_id"
                                placeholder="Select one"
                                className="w-full"
                                options={campaign.contest_actions
                                  ?.filter(actionsFilter)
                                  ?.map((ca) => ({
                                    value: ca.id,
                                    isDisabled: userContestReq.data.actions.find(a => a.id === ca.id)?.completed,
                                    label: (
                                      <div className="text-sm py-1 capitalize">
                                        {splitAndCapitalizeTemplateString(
                                          ca.template
                                        )}{" "}
                                        - {commafyNumber(ca.point_value)}{" "}
                                        {pluralize(creditName, ca.point_value)}
                                        <div className="text-xs italic">
                                          {ca.name ||
                                            ca.content.title ||
                                            ca.content.description ||
                                            ca.content.question ||
                                            ca.content.text ||
                                            ca.content.name ||
                                            ca.content.band_name}
                                        </div>
                                      </div>
                                    ),
                                  }))}
                              />
                            </div>
                            <ErrorMessageComponent name="contest_action_id" />

                            <div className="flex flex-row gap-3">
                              <div className="basis-1/2">
                                <label
                                  htmlFor="first_name"
                                  className="block text-sm text-gray-500 mb-1"
                                >
                                  First Name
                                </label>
                                
                                <Field
                                  id="first_name"
                                  name="first_name"
                                  className="p-2 border border-solid border-gray-300 rounded w-full text-sm"
                                />
                                <ErrorMessageComponent name="first_name" />
                              </div>

                              <div className="basis-1/2">
                                <label
                                  htmlFor="last_name"
                                  className="block text-sm text-gray-500 mb-1"
                                >
                                  Last Name
                                </label>
                                <Field
                                  id="last_name"
                                  name="last_name"
                                  className="p-2 border border-solid border-gray-300 rounded w-full text-sm"
                                />
                                <ErrorMessageComponent name="last_name" />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="phone"
                                className="block text-sm text-gray-500 mb-1"
                              >
                                Phone
                              </label>
                              <FormikPhoneField />
                              <ErrorMessageComponent name="phone" />
                            </div>

                            <div className="flex flex-row gap-3">
                              <div className="basis-2/3">
                                <label
                                  htmlFor="address_1"
                                  className="block text-sm text-gray-500 mb-1"
                                >
                                  Address Line 1
                                </label>
                                <Field
                                  id="address_1"
                                  name="address_1"
                                  placeholder="Street Address"
                                  className="p-2 border border-solid border-gray-300 rounded w-full text-sm"
                                />
                                <ErrorMessageComponent name="address_1" />
                              </div>

                              <div className="basis-1/3">
                                <label
                                  htmlFor="address_2"
                                  className="block text-sm text-gray-500 mb-1"
                                >
                                  Address Line 2
                                </label>
                                <Field
                                  id="address_2"
                                  name="address_2"
                                  placeholder="Unit, Apt, etc."
                                  className="p-2 border border-solid border-gray-300 rounded w-full text-sm"
                                />
                                <ErrorMessageComponent name="address_2" />
                              </div>
                            </div>

                            <div className="flex flex-row gap-3">
                              <div className="basis-2/3">
                                <label
                                  htmlFor="city"
                                  className="block text-sm text-gray-500 mb-1"
                                >
                                  City
                                </label>
                                <Field
                                  id="city"
                                  name="city"
                                  className="p-2 border border-solid border-gray-300 rounded w-full text-sm"
                                />
                                <ErrorMessageComponent name="city" />
                              </div>

                              <div className="basis-1/3">
                                <label
                                  htmlFor="state"
                                  className="block text-sm text-gray-500 mb-1"
                                >
                                  State/Region
                                </label>
                                <Field
                                  id="state"
                                  name="state"
                                  className="p-2 border border-solid border-gray-300 rounded w-full text-sm"
                                />
                                <ErrorMessageComponent name="state" />
                              </div>
                            </div>

                            <div className="flex flex-row gap-3">
                              <div className="basis-2/3">
                                <label
                                  htmlFor="country"
                                  className="block text-sm text-gray-500 mb-1"
                                >
                                  Country
                                </label>
                                <Field
                                  id="country"
                                  name="country"
                                  className="p-2 border border-solid border-gray-300 rounded w-full text-sm"
                                />
                                <ErrorMessageComponent name="country" />
                              </div>

                              <div className="basis-1/3">
                                <label
                                  htmlFor="postal_code"
                                  className="block text-sm text-gray-500 mb-1"
                                >
                                  Postal Code
                                </label>
                                <Field
                                  id="postal_code"
                                  name="postal_code"
                                  className="p-2 border border-solid border-gray-300 rounded w-full text-sm"
                                />
                                <ErrorMessageComponent name="postal_code" />
                              </div>
                            </div>

                            <div>
                              <FormikRecaptchaField />
                              <ErrorMessageComponent name="recaptcha_token" />
                            </div>

                            <button
                              type="submit"
                              disabled={!dirty || loading}
                              className={classnames(
                                "btn-primary text-white uppercase rounded mt-6 w-full",
                                {
                                  "cursor-not-allowed opacity-50": !dirty,
                                }
                              )}
                            >
                              {loading ? (
                                <i className="icon-loading"></i>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
      <Footer />
      <FlashNotification
        isOpen={!!showErrorMsg}
        timeout={5000}
        handleClose={() => setShowErrorMsg(false)}
      >
        <div className="flex items-center">
          <div className="text-red-500">
            <CloseOutlineIcon className="h-6 w-6 fill-current" />
          </div>
          <p className="ml-3 capitalize">{showErrorMsg}</p>
        </div>
      </FlashNotification>
    </Fragment>
  );
}

function splitAndCapitalizeTemplateString(str) {
  const words = str.split("-");
  const capitalizedWords = words.map((word) => {
    if (!word) {
      return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(" ");
}

function FormikPhoneField({ label, ...props }) {
  const [field, meta, helpers] = useField("phone");
  const { value, setValue } = helpers;

  const [isFocusing, setIsFocusing] = useState(false);

  const onFocus = () => {
    setIsFocusing(true);
  };

  const onBlur = () => {
    setIsFocusing(false);
  };

  return (
    <div
      className={classnames("bg-white p-2 rounded", {
        "border border-blue-700 ring-1 ring-blue-700 rounded": isFocusing,
        "border-gray-300 border rounded": !isFocusing,
      })}
    >
      <PhoneInput
        placeholder="Enter phone number"
        value={value}
        defaultCountry="US"
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={setValue}
        className="w-full"
      />
    </div>
  );
}

function FormikSelectField({ name, ...props }) {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <Select
      isClearable={true}
      options={props.options}
      placeholder={props.placeholder}
      onChange={(option) => setValue(option?.value)}
      classNamePrefix="react-select"
    />
  );
}

const ErrorMessageComponent = (props) => {
  return (
    <ErrorMessage {...props}>
      {(msg) => (
        <div className="flex items-center text-red-500 text-xs mt-1">
          <ExclamationCircleIcon className="h-4 w-4 mr-1" /> {msg}
        </div>
      )}
    </ErrorMessage>
  );
};

function FormikRecaptchaField({ label, ...props }) {
  const [field, meta, helpers] = useField("recaptcha_token");
  const { setValue } = helpers;

  return (
    <div>
      <ReCAPTCHA
        sitekey={constants.KEYS.google.reCaptchaKey}
        tabIndex="3"
        className="w-full"
        onChange={(token) => {
          setValue(token);
        }}
      />
    </div>
  );
}

export default WriteIn;
