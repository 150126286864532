import React, { useEffect, useState, useContext } from 'react';
import { Route } from 'react-router-dom';
import constants from '../../constants';
import AuthContext from '../../contexts/AuthContext';

function ProtectedRoute(props) {
  const { session } = useContext(AuthContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (session.complete && !session.success) {
      setIsAuthenticated(false);
      window.location.replace(`${constants.EMBER_APP_URL}/login?redirect=${encodeURIComponent(window.location.href.toString())}`);
    } else if (session.complete && session.success) {
      setIsAuthenticated(true);
    }
  }, [session.complete, session.success]);

  return isAuthenticated ? <Route {...props} /> : null;
}


export default ProtectedRoute;
