import React, { useEffect, useState, useContext } from 'react';
import TS from '../../api';
import AuthContext from '../../contexts/AuthContext';

const BABLIC_URL = '//d.bablic.com/snippet/5d31f567bc7c230001f6d66f.js?version=3.9';
const SUPPORTED_LANGUAGES = ['en', 'fr', 'de', 'es'];
const DEFAULT_LANGUAGE = 'en';

const browserLocale = (function () {
  const n = window.navigator;
  return n.languages && n.languages[0] || // Chrome / Firefox
         n.language || // All browsers
         n.userLanguage; // IE <= 10
})();

export const browserLanguage = (function () {
  return searchStringToHash().locale || browserLocale.split('-')[0]
})();

function searchStringToHash() {
  return window.location.search.slice(1).split("&")
  .map(v => v.split("="))
  .reduce( (pre, [key, value]) => ({ ...pre, [key]: value }), {} );
};

function Localization() {
  const { session } = useContext(AuthContext);
  const [bablic, setBablic] = useState(null);
  const [bablicLoading, setBablicLoading] = useState(null);

  const onBablicLoad = () => {
    setBablic(window.bablic);
    setBablicLoading(false);
  }

  const updateBablicLanguage = () => {
    let lang = browserLanguage;
    const locale = session?.data?.profile?.locale;
    if (locale) {
      lang = locale;
    }

    lang = SUPPORTED_LANGUAGES.includes(lang) ? lang : DEFAULT_LANGUAGE;
    // Set Language on Request Headers
    TS.setLanguage(lang);
    if (bablic && bablic.getLocale() !== lang && lang !== DEFAULT_LANGUAGE) {
      bablic.setLanguage(lang);
    }
  }

  useEffect(() => {
    if (session?.data?.profile?.locale) {
      updateBablicLanguage();
    }
  }, [bablic, session?.data?.profile?.locale]);

  if (bablic || bablicLoading ) return null;

  const script = document.createElement('script');
  script.src = BABLIC_URL;
  script.async = true;
  document.addEventListener('bablicload', onBablicLoad);
  setBablicLoading(true);
  document.body.appendChild(script);

  return null;
}

export default Localization;