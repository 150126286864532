import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import useScrollTop from '../../hooks/useScrollTop';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import AwsMarkdown from '../../components/AwsMarkdown';

function AboutOurAds(props) {
  useScrollTop(props);

  return (
    <Fragment>
      <Helmet>
        <title>Tunespeak / About Our Ads</title>
      </Helmet>
      <div className="bg-gray-100 min-h-screen">
        <Navbar className="bg-blue-gradient text-white"/>
        <div className="max-w-screen-lg mx-auto py-10 px-6" bablic-exclude="true">
          <AwsMarkdown bucket="tunespeak-cms" file="about-our-ads.md" />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default AboutOurAds;