import React, { useContext } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import useAsyncState from '../../../hooks/useAsyncState';
import BackgroundImage from '../../../components/BackgroundImage';
import TS from '../../../api';
import {
  getOrientation,
  resetOrientation
} from '../../../utils/image-orientation';

export default function Avatar() {
  const { session, update: updateSession } = useContext(AuthContext);
  const [error, setError] = useAsyncState(null);
  const [avatarUrl, setAvatarUrl] = useAsyncState(session?.data?.photo);
  const [isUploading, setIsUploading] = useAsyncState(false);
  const [isDeleting, setIsDeleting] = useAsyncState(false);

  const reloadSession = () => {
    TS.get('/sessions').then((res) => {
      updateSession((prev) => ({
        ...prev,
        data: res
      }));
    });
  };

  const renderPhoto = () => {
    if (!isUploading) {
      setError(null);
      const $el = document.getElementById('user-avatar');
      const img = $el.files[0];
      if (img.size >= 4000000) {
        setError('File size can be no larger than 4MB');
        return;
      }
      setIsUploading(true);
      const reader = new FileReader();
      reader.onload = () => {
        // Get image Orientation
        getOrientation(img).then((orientation) => {
          // Apply Orientation so Browser understands
          resetOrientation(reader.result, orientation).then((dataUrl) => {
            updatePhoto(img, dataUrl);
          });
        });
      };
      reader.readAsDataURL(img);
    }
  };

  const getSignedUrl = (img) => {
    return TS.post('/user_avatars', {
      user_avatar: {
        file_name: img.name,
        file_type: img.type,
        file_size: img.size
      }
    });
  };

  const uploadAvatarToAws = (url, img) => {
    return TS.request({
      type: 'put',
      base: url,
      options: {
        data: img
      }
    });
  };

  const updatePhoto = (img, dataUrl) => {
    const handleError = (err) => {
      setError(err?.response?.data?.message || 'Something went wrong');
      setIsUploading(false);
    };

    if (img) {
      getSignedUrl(img)
        .then((res) => {
          uploadAvatarToAws(res.user_avatar.upload_url, img)
            .then(() => {
              TS.put(`/user_avatars/${res.user_avatar.id}`, {
                user_avatar: {
                  file_name: img.name,
                  file_type: img.type,
                  file_size: img.size,
                  upload_url: res.user_avatar.upload_url,
                  uploaded: true
                }
              })
                .then(() => {
                  setIsUploading(false);
                  setAvatarUrl(dataUrl);
                  reloadSession({
                    path: '/sessions'
                  });
                })
                .catch(handleError);
            })
            .catch(handleError);
        })
        .catch(handleError);
    }
  };

  const removePhoto = () => {
    if (!isDeleting) {
      setIsDeleting(true);
      TS.delete('/user_avatars')
        .then(() => {
          setIsDeleting(false);
          reloadSession({
            path: '/sessions'
          });
        })
        .catch(() => setIsDeleting(false));
    }
  };

  return (
    <div className="flex flex-col sm:flex-row justify-between items-center p-3 sm:p-4">
      <div>
        <p>Photo</p>
        <BackgroundImage src={avatarUrl} className="rounded-full h-16 w-16 pt-1"/>
        {session?.data?.uploaded_avatar && (
          <button className="btn-sm btn-border btn-round" onClick={removePhoto}>
            {isDeleting ? <i className="icon-loading"></i> : 'Remove'}
          </button>
        )}
      </div>

      <div className="pt-3 sm:pt-0 sm:pl-6 w-full flex flex-col items-center sm:items-end">
        {error && <p className="text-red-500 text-xs mb-1">{error}</p>}
        <div className="flex w-full sm:w-auto items-center">
          {isUploading && <i className="icon-loading mr-3"></i>}
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            id="user-avatar"
            className="block border rounded w-full sm:w-auto text-sm"
            onChange={renderPhoto}
          />
        </div>
        <p className="text-xs text-gray-600 py-1">
          Must be JPEG or PNG and cannot exceed 4MB
        </p>
      </div>
    </div>
  );
}
