import React, { useState, useContext } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import useAsyncState from '../../../hooks/useAsyncState';
import Modal from '../../../components/Modal';
import TS from '../../../api';

export default function Language() {
  const { session, update: updateSession } = useContext(AuthContext);
  const profile = session?.data?.profile;
  const [loading, setLoading] = useAsyncState(false);
  const [isEditingLanguage, setIsEditingLanguage] = useAsyncState(false);
  const [language, setLanguage] = useState(profile.locale);

  const localeToLang = (locale) => {
    switch (locale) {
      case 'en':
        return 'English';
      case 'fr':
        return 'Français';
      case 'de':
        return 'Deutsch';
      case 'es':
        return 'Español';
      default:
        return 'Auto Detect';
    }
  };

  const handleChange = (e) => {
    switch (e.target.id) {
      case 'lang_en':
        setLanguage('en');
        break;
      case 'lang_fr':
        setLanguage('fr');
        break;
      case 'lang_es':
        setLanguage('es');
        break;
      case 'lang_de':
        setLanguage('de');
        break;
      default:
        setLanguage(null);
        break;
    }
  };

  const updateLanguage = () => {
    if (!loading) {
      setLoading(true);
      TS.put(`/user_profiles/${profile.id}`, {
        user_profile: {
          ...profile,
          locale: language
        }
      })
        .then((res) => {
          setLoading(false);
          setIsEditingLanguage(false);
          updateSession((prev) => {
            const ret = Object.assign({}, prev);
            ret.data.profile = res.user_profile;
            return ret;
          });
          TS.setLanguage(language);
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div className="flex justify-between items-center p-3 sm:p-4 border-t">
      <div>
        <p>Language</p>
        <p className="text-xs text-gray-600">{localeToLang(profile.locale)}</p>
      </div>
      <button
        className="btn btn-border btn-round"
        onClick={() => setIsEditingLanguage(true)}
      >
        Edit
      </button>
      <Modal
        open={isEditingLanguage}
        onClose={() => setIsEditingLanguage(false)}
      >
        <fieldset className="bg-white rounded p-4 lg:p-6 mt-6">
          <legend className="text-base leading-6 font-medium text-gray-900">
            Language
          </legend>
          <p className="text-sm leading-5 text-gray-500">
            Select your language preference.
          </p>
          <div className="mt-4">
            <div className="flex items-center">
              <input
                id="lang_auto"
                name="push_notifications"
                type="radio"
                checked={language === null}
                className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                onChange={handleChange}
              />
              <label htmlFor="lang_auto" className="ml-3">
                <span className="block text-sm leading-5 font-medium text-gray-700">
                  Automatically Detect
                </span>
              </label>
            </div>
            <div className="mt-4 flex items-center">
              <input
                id="lang_en"
                name="push_notifications"
                type="radio"
                checked={language === 'en'}
                className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                onChange={handleChange}
              />
              <label htmlFor="lang_en" className="ml-3">
                <span bablic-exclude="true" className="block text-sm leading-5 font-medium text-gray-700">
                  English
                </span>
              </label>
            </div>
            <div className="mt-4 flex items-center">
              <input
                id="lang_fr"
                name="push_notifications"
                type="radio"
                checked={language === 'fr'}
                className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                onChange={handleChange}
              />
              <label htmlFor="lang_fr" className="ml-3">
                <span bablic-exclude="true" className="block text-sm leading-5 font-medium text-gray-700">
                  Français
                </span>
              </label>
            </div>
            <div className="mt-4 flex items-center">
              <input
                id="lang_es"
                name="push_notifications"
                type="radio"
                checked={language === 'es'}
                className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                onChange={handleChange}
              />
              <label htmlFor="lang_es" className="ml-3">
                <span bablic-exclude="true" className="block text-sm leading-5 font-medium text-gray-700">
                  Español
                </span>
              </label>
            </div>
            <div className="mt-4 flex items-center">
              <input
                id="lang_de"
                name="push_notifications"
                type="radio"
                checked={language === 'de'}
                className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                onChange={handleChange}
              />
              <label htmlFor="lang_de" className="ml-3">
                <span bablic-exclude="true" className="block text-sm leading-5 font-medium text-gray-700">
                  Deutsch
                </span>
              </label>
            </div>
          </div>
          <button
            className="btn btn-border btn-round w-full mt-6"
            onClick={updateLanguage}
          >
            {loading ? <i className="icon-loading"></i> : 'Save'}
          </button>
        </fieldset>
      </Modal>
    </div>
  );
}
