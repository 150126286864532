import React, { Fragment, useEffect, useState, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import history from '../../history';
import constants from '../../constants';
import Navbar from '../../components/Navbar';
import LoadingPage from '../../components/LoadingPage';
import RequiresPassword from '../../components/RequiresPassword';
import useScrollTop from '../../hooks/useScrollTop';
import useRequest from '../../hooks/useRequest';

const TourListing = React.lazy(() =>
  import('@tunespeak/ts-presale-registration').then((module) => ({
    default: module.Tour
  }))
);

function Tour(props) {
  const id = props.match.params.id;
  useScrollTop(props);
  const [tourRequest] = useRequest({
    type: 'get',
    path: `/tours/${id}`
  });

  const tour = tourRequest?.data?.tour;

  useEffect(() => {
    if (tourRequest.errors) {
      // 403 handled by RequiresPassword
      if (tourRequest.errors?.response?.status !== 403) {
        history.replace('/404');
      }
    }
  }, [tourRequest.errors]);

  if (tour) {
    const tour = tourRequest.data?.tour;
    const { name, og_title, og_description, facebook_post_photo, theme_css, background_photo, header_photo } = tour;
  
    return (
      <Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <title bablic-exclude="true">{name} - Tunespeak</title>
          <meta
            property="og:title"
            bablic-exclude="true"
            content={og_title}
          />
          {og_description && (
            <meta
              property="og:description"
              bablic-exclude="true"
              content={og_description}
            />
          )}
          <meta property="og:image" content={facebook_post_photo} />

          <meta
            name="twitter:title"
            bablic-exclude="true"
            content={og_title}
          />
          {og_description && (
            <meta
              name="twitter:description"
              bablic-exclude="true"
              content={og_description}
            />
          )}
          <meta name="twitter:image" content={facebook_post_photo} />
          {theme_css && <style>{theme_css}</style>}
        </Helmet>

        <div className="Tour__bg relative bg-gray-200 min-h-screen overflow-auto">
          {background_photo && <div className="Tour__bg-photo z-10 fixed inset-0 w-full h-full bg-no-repeat bg-cover bg-center" style={{backgroundImage: `url(${background_photo})`}} />}
          <Navbar className="relative bg-black text-white z-20" />
          <div className="Tour relative max-w-screen-sm mx-auto my-10 p-4 z-20" bablic-exclude="true">
            {header_photo && <img src={header_photo} alt="" className="Tour__photo" />}
            <Suspense fallback={null}>
              <TourListing tourId={id} tourData={tour} />
            </Suspense>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <RequiresPassword errors={tourRequest.errors} cookie="Tour_pwd">
      <LoadingPage />
    </RequiresPassword>
  );
}

export default Tour;
