import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function BackgroundImage(props) {
  const { src, size = 'cover', className } = props;
  const style = {
    backgroundImage: `url('${src}')`,
    backgroundSize: size,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: '#0e0e0e',
  };
  return <div style={style} className={classnames('background-image', className)} />;
}

BackgroundImage.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default BackgroundImage;
