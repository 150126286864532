import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { normalize } from 'normalizr';
import Toggle from '../../components/Toggle';
import NoContent from '../../components/NoContent';
import Loading from '../../components/Loading';
import userBandsModel from '../../models/userBands';
import useRequest from '../../hooks/useRequest';
import useScrollTop from '../../hooks/useScrollTop';
import AuthContext from '../../contexts/AuthContext';
import TS from '../../api';

export default function ArtistMessages(props) {
  useScrollTop(props);

  const { session } = useContext(AuthContext);
  const profile = session?.data?.profile;
  const [userBandsReq, fetchUserBands, updateUserBands] = useRequest();

  const toggleCallback = (val, id) => {
    TS.put(`/user_bands/${id}`, {
      user_band: {
        notifications_enabled: val
      }
    }).then(() => {
      updateUserBands((prev) => {
        const ret = Object.assign({}, prev);
        ret.data.user_bands[id].notifications_enabled = val;
        return ret;
      });
    });
  };

  const userBands = userBandsReq?.data?.user_bands;

  useEffect(() => {
    if (profile.email_band_message && profile.email_enabled) {
      fetchUserBands({
        type: 'get',
        path: '/user_bands',
        serializer: (res) => {
          return normalize(res.user_bands, userBandsModel).entities;
        }
      });
    }
  }, [profile.email_band_message, profile.email_enabled]);

  return (
    <div className="mx-auto max-w-screen-lg pt-6 pb-12 px-4 lg:px-0">
      <Link to="/settings/notifications" className="link-blue text-sm">
        <i className="icon-arrow-left"></i> Back to Notifications
      </Link>
      <header className="mt-3">
        <h1 className="font-normal text-2xl">Artist Messages</h1>
        {profile.email_band_message && profile.email_enabled ? (
          <p className="text-sm">
            Artists can reach out to you directly via Tunespeak with rewards,
            exclusive content, etc. You may configure which artist are able to
            reach out to you via email below.
          </p>
        ) : (
          <p className="text-sm">
            You have disabled all artist messages. Please return to the{' '}
            <Link to="/settings/notifications" className="link-blue">
              notifications
            </Link>{' '}
            page and enable <b>Email Notifications</b> and{' '}
            <b>Artist Messages</b> if you would like to configure notifications
            per artist.
          </p>
        )}
      </header>
      <div className="bg-white overflow-hidden rounded-sm shadow-sm mt-6">
        {userBandsReq.loading && <Loading className="p-6" />}
        {userBandsReq.success && (!userBands || !Object.keys(userBands).length) && (
          <NoContent className="text-gray-600 text-sm">
            You're not subscribed to any artist message notifications.
          </NoContent>
        )}
        {userBands &&
          Object.keys(userBands).map((key) => (
            <div
              className="flex items-center p-3 sm:p-4 border-t first:border-t-0"
              key={key}
            >
              <img
                src={userBands[key].band_photo}
                alt=""
                className="h-10 w-10 rounded-full"
              />
              <p
                bablic-exclude="true"
                className="flex flex-grow leading-5 mx-3 truncate"
              >
                {userBands[key].band_name}
              </p>
              <Toggle
                initial={userBands[key].notifications_enabled}
                callback={(val) => toggleCallback(val, key)}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
