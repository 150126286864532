import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';

function Modal(props) { 
  const { open, onClose } = props;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    }
  }, [open])

  return (
    <Transition
      show={open}
      className="fixed z-10 inset-0 overflow-y-auto"
      enter="transition-opacity duration-300 ease-in"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200 ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
       <div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0">
        {/* Background overlay, show/hide based on modal state. */}
        <div className="fixed inset-0" onClick={onClose}>
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>

        {/*This element is to trick the browser into centering the modal contents.*/}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

        {/* Modal panel, show/hide based on modal state. */}
        <div className="inline-block align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          {props.children}
        </div>
      </div>
    </Transition>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default Modal;
