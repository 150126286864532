const KEYS = {
  airbrake: {
    projectId: 215004,
    projectKey: "f1d410b8511d6edec953304f58d60106",
  },

  googleAnalytics: {
    gaMeasurementId: "G-6RNHWRZNKT",
  },

  google: {
    key: "AIzaSyCEs-seZjKeDPpvXfY87shqNqvcDLg8wUQ",
    reCaptchaKey: "6LeF0VUUAAAAAOWhQo5kPUfZHghkn0fQJvxLMIzq",
  },

  facebook: {
    appId: "384503444962200",
  },

  authTokenName: "auth-token-dev",
};

let EMBER_APP_URL = "http://localhost:3001";
let REACT_APP_URL = "http://localhost:3004";
let WISHES_APP_URL = "http://localhost:3005";
let API_ROOT = "http://localhost:3000";
let RULES_BUCKET;

if (process.env.REACT_APP_ENV === "staging") {
  EMBER_APP_URL = "https://www.stagingspeak.com";
  REACT_APP_URL = "https://www.stagingspeak.com";
  WISHES_APP_URL = "https://wishes.stagingspeak.com";
  API_ROOT = "https://api.stagingspeak.com";
  RULES_BUCKET = "stagingspeak-rules";

  KEYS.facebook.appId = "504832369535061";
  KEYS.googleAnalytics.gaMeasurementId = "G-5SXB56PFCQ";
  KEYS.google.key = "AIzaSyAGdyvqg8Au0yjyl-VQ9Getc8wEb74-VD4";
  KEYS.google.reCaptchaKey = "6LdD0VUUAAAAAJgn9eegBIxSErpuF3OGMEle-7gO";
  KEYS.authTokenName = "auth-token-stage";
}

if (process.env.REACT_APP_ENV === "production") {
  EMBER_APP_URL = "https://www.tunespeak.com";
  REACT_APP_URL = "https://www.tunespeak.com";
  WISHES_APP_URL = "https://wishes.tunespeak.com";
  API_ROOT = "https://api.tunespeak.com";
  RULES_BUCKET = "tunespeak-rules";

  KEYS.facebook.appId = "116527605044984";
  KEYS.googleAnalytics.gaMeasurementId = "G-64CFFWP56P";
  KEYS.google.key = "AIzaSyD5aIrE5n2wo8XxqT7p0iMIX0T6YbIrhpA";
  KEYS.google.reCaptchaKey = "6LdE0VUUAAAAAD8XBQwxCj3r2w_lUgfjJDUTKRjG";
  KEYS.authTokenName = "auth-token";
}

if (process.env.REACT_APP_ENV === "beta") {
  EMBER_APP_URL = "https://beta.tunespeak.com";
  REACT_APP_URL = "https://beta.tunespeak.com";
  WISHES_APP_URL = "https://beta-wishes.tunespeak.com";
  API_ROOT = "https://beta-api.tunespeak.com";
  RULES_BUCKET = "tunespeak-rules";

  KEYS.facebook.appId = "116527605044984";
  KEYS.googleAnalytics.gaMeasurementId = "G-64CFFWP56P";
  KEYS.google.key = "AIzaSyD5aIrE5n2wo8XxqT7p0iMIX0T6YbIrhpA";
  KEYS.google.reCaptchaKey = "6LdE0VUUAAAAAD8XBQwxCj3r2w_lUgfjJDUTKRjG";
  KEYS.authTokenName = "auth-token";
}

export default {
  EMBER_APP_URL,
  REACT_APP_URL,
  WISHES_APP_URL,
  API_ROOT,
  KEYS,
  RULES_BUCKET,
};
