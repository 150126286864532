import axios from 'axios';
import constants from './constants';

// configure defaults for tunespeak api
const tsAxios = axios.create({
  baseURL: constants.API_ROOT,
  withCredentials: true
});

const request = (type, url, body) => {
  return new Promise((resolve, reject) => {
    tsAxios[type](url, body).then(res => resolve(res.data)).catch(error => reject(error.response));
  }); 
};

const TS_API = {
  get: (url) => {
    return tsAxios.get(url).then(res => res.data);
  },
  post: (url, body = {}) => {
    return tsAxios.post(url, body).then(res => res.data);
  },
  put: (url, body = {}) => {
    return tsAxios.put(url, body).then(res => res.data);
  },
  delete: (url, body = {}) => {
    return tsAxios.delete(url, body).then(res => res.data);
  },
  setLanguage(lang) {
    // always set lang on Headers
    tsAxios.interceptors.request.use(function (config) {
      if (lang) {
        config.headers['accept-language'] = lang;
      }
      return config;
    });
  },
  request({ type, path, body, base, options }) {
    if (base) {
      return new Promise((resolve, reject) => {
        axios({method: type, url: `${base}${path ? path : ''}`, data: body, ...options })
          .then(res => resolve(res.data))
          .catch(error => reject(error.response));
      }); 
    }
    return request(type, path, body);
  }
}

export default TS_API;
