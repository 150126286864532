import React, { Fragment, useEffect, useState, Suspense } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import history from '../../history';
import constants from '../../constants';
import Navbar from '../../components/Navbar';
import RequiresPassword from '../../components/RequiresPassword';
import useScrollTop from '../../hooks/useScrollTop';
import useRequest from '../../hooks/useRequest';

const RegistrationForm = React.lazy(() =>
  import('@tunespeak/ts-presale-registration').then(module => ({ default: module.RegistrationForm }))
);

function Form(props) {
  const id = props.match.params.id;
  useScrollTop(props);
  const [formRequest] = useRequest({
    type: 'get',
    path: `/registration_forms/${id}`
  });

  
  const [dontLoadYet, setDontLoadYet] = useState(true);
  const registrationForm = formRequest?.data?.registration_form;
  const search = queryString.parse(props.location.search);

  useEffect(() => {
    if (!registrationForm) return;
    const registrationUrl = registrationForm.registration_url;

    if (registrationUrl) {
      const regex = new RegExp(`^${constants.REACT_APP_URL}/form/${id}`);
      if (!regex.test(registrationUrl)) {
        if (!/Prerender/ig.test(navigator.userAgent)) {
          const redirectUrl = queryString.stringifyUrl({url: registrationUrl, query: search});
          return window.location.replace(redirectUrl);
        }
      }
    }

    setDontLoadYet(false);
  }, [registrationForm]);

  useEffect(() => {
    if (formRequest.errors) {
      // 403 handled by RequiresPassword
      if (formRequest.errors?.status !== 403) {
        history.replace('/404');
      }
    }
  }, [formRequest.errors]);

  if (!dontLoadYet && registrationForm) {
    const search = window.location.search;
    const form = formRequest.data?.registration_form;
    const { name, og_title, og_description, facebook_post_photo, theme_css, header_photo, background_photo } = form;
    
    return (
      <Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <title bablic-exclude="true">{name} - Tunespeak</title>
          <meta property="og:title" bablic-exclude="true" content={og_title} />
          {og_description && <meta property="og:description" bablic-exclude="true" content={og_description} />}
          <meta property="og:image" content={facebook_post_photo} />

          <meta name="twitter:title" bablic-exclude="true" content={og_title} />
          {og_description && <meta name="twitter:description" bablic-exclude="true" content={og_description} />}
          <meta name="twitter:image" content={facebook_post_photo} />
          {theme_css && <style>{theme_css}</style>}
        </Helmet>
        
        <div className="Form__bg relative bg-gray-200 min-h-screen overflow-auto">
          {background_photo && <div className="Form__bg-photo z-10 fixed inset-0 w-full h-full bg-no-repeat bg-cover bg-center" style={{backgroundImage: `url(${background_photo})`}} />}
          <Navbar className="relative bg-black text-white z-20" />
          <div className="Form relative max-w-screen-sm mx-auto my-10 p-4 z-20" bablic-exclude="true">
            {header_photo && <img src={header_photo} alt="" className="Form__photo" />}
            <Suspense fallback={null}>
              <Switch>
                <Route exact path="/form/:id">
                  <Fragment>
                    <RegistrationForm formId={id} />
                  </Fragment>
                </Route>
                <Route exact path="/form/:id/enter-to-win">
                  <Fragment>
                    <div className="text-center">
                      <a href={`/form/${form.id}${search}`} className="tssdk-link">&larr; Back</a>
                    </div>
                    <RegistrationForm formId={id} initState="campaign" />
                  </Fragment>
                </Route>
              </Switch>
            </Suspense>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <RequiresPassword errors={formRequest.errors} cookie="form_pwd" />
  );
}

export default Form;