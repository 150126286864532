import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as JobsBizDevIcon } from '../../assets/images/jobs-biz-dev.svg';
import { ReactComponent as JobsServerDevIcon } from '../../assets/images/jobs-server-dev.svg';

export default function () {
  return (
    <Fragment>
      <h1 className="text-2xl sm:text-4xl leading-tight font-medium">Jobs</h1>
      <p className="text-sm">
        At Tunespeak, we strive to build beautiful and intuitive tools for artists and creators to engage their fan bases in exciting ways.
        Our marketing tools are the most used, trusted and advanced in the music industry, serving thousands of musicians and millions of fans.
      </p>
      <p className="text-sm">
        In addition to music, we built an innovative sports platform called <a className="link-blue" href="https://bluenatics.stlouisblues.com" target="_blank">Bluenatics</a> with
        the St. Louis Blues, 2019 Stanley Cup Champions. We are in the midst of launching a new platform called <a className="link-blue" target="_blank" href="https://wishes.tunespeak.com">Wi$hes</a>.
        It provides artists, influencers, and content-creators with a unique way to raise money while also crowd-sourcing new ideas for content.
        Join the team and be part of some awesome projects and partnerships in the pipeline!
      </p>
      <p className="text-sm">
        Tunespeak is currently located in St. Louis, MO. Being able to work in the St. Louis area is preferred once COVID restrictions subside.
      </p>
      <p className="text-sm">
        Please send your résumé and a link to your portfolio, if applicable, to <a className="link-blue" href="mailto:jobs@tunespeak.com">jobs@tunespeak.com</a>.
      </p>

      <h2 className="text-lg sm:text-2xl leading-tight font-medium">Currently Seeking</h2>

      <div className="space-y-4">
        <Link className="flex hover:text-blue-700 items-center bg-white p-5 shadow" to="/jobs/server-developer">
          <JobsServerDevIcon className="w-10 mr-4 stroke-current" />
          <div className="flex-1">
            <div className="sm:text-lg font-medium">Server Developer</div>
            <p className="text-xs">Ruby on Rails, MongoDB, Elasticsearch, Node.js, Docker, AWS</p>
          </div>
          <span className="text-2xl">&rsaquo;</span>
        </Link>
        <Link className="flex items-center bg-white p-5 shadow" to="/jobs/biz-operations-manager">
          <JobsBizDevIcon className="w-10 mr-4 stroke-current" />
          <div className="flex-1">
            <div className="sm:text-lg font-medium">Biz Operations Manager</div>
            <p className="text-xs">Campaign Setup & Management</p>
          </div>
          <span className="text-2xl">&rsaquo;</span>
        </Link>
      </div>
    </Fragment>
  );
}
