import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import SportsLogo from "../../assets/images/sports_logo.svg";
import SportsShare from "../../assets/images/sports_share.png";
import constants from '../../constants';

const Sports = () => {
  const title = "Sports by Tunespeak";
  const description = "Give your fans more wins all year long.";

  return (
    <div className="Sports w-full h-screen flex justify-center items-center text-white">
      <Helmet>
        <title>{title}</title>
        <meta name="googlebot" content="index" />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${constants.REACT_APP_URL}${SportsShare}`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:image"
          content={`${constants.REACT_APP_URL}${SportsShare}`}
        />
        <meta name="twitter:site" content="@tunespeak" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <div className="text-center">
        <img src={SportsLogo} alt="" className="h-16 inline-block md:h-24" />
        <div className="pt-6 text-2xl md:text-4xl united text-center">
          Give your fans{" "}
          <span className="united-italic text-sports">more wins</span> <br />
          all year long.
        </div>

        <div className="mt-6 inline-block h-px w-2/3 bg-sports"></div>

        <div className="pt-6 text-lg md:text-xl font-light">
          Request a meeting to find out why <br />
          we're a trusted fan engagement leader.
        </div>

        <Link
          to="/sports/demo-request"
          className="mt-6 inline-block btn-sports text-2xl py-3 px-8 united uppercase -skew-x-12"
        >
          REQUEST A MEETING
        </Link>
      </div>
    </div>
  );
}

export default Sports;