import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

function PresaleListItem(props) {
  const { presale, className } = props;
  
  const presaleEnded = DateTime.fromISO(presale.ends_at).plus({hours: 12}).diffNow() > 0;
  const ticketsOnSale = DateTime.fromISO(presale.on_sale_at).diffNow() > 0;

  const search = window.location.search;
  const extUrl = presale.ext_on_sale_url || presale.ext_url;

  let button = <Link to={{ pathname: `/presale/${presale.id}`, search }} className="PresaleListItem__btn btn btn-border uppercase w-full">Presale</Link>;
  if (presale.ends_at && presaleEnded && extUrl) {
    button =  <a href={extUrl} target="_blank" rel="noopener noreferrer" className="PresaleListItem__btn btn btn-border uppercase w-full">On Sale Soon</a>
    if (ticketsOnSale) {
      button = <a href={extUrl} target="_blank" rel="noopener noreferrer" className="PresaleListItem__btn btn btn-border uppercase w-full">Buy Tickets</a>
    }
  }

  return (
    <li className="PresaleListItem flex flex-col sm:flex-row sm:items-center py-5 border-t first:border-t-0">
      <div className="PresaleListItem__date text-xs uppercase font-bold sm:min-w-32">
        {DateTime.fromISO(presale.show_starts_at, {setZone: true}).toFormat('EEE, MMM d')}
      </div>
      <div className="PresaleListItem-location mb-4 sm:mb-0 sm:flex-1">
        <div className="PresaleListItem-location__city text-lg font-medium tracking-wider">
          {presale.show_location}
        </div>
        <div className="PresaleListItem-location__venue text-sm font-light">
          {presale.show_venue}
        </div>        
      </div>
      <div className="sm:min-w-40">
        {button}
      </div>
    </li>
  );
};

PresaleListItem.propTypes = {
  presale: PropTypes.shape({
    id: PropTypes.string.isRequired,
    show_starts_at: PropTypes.string.isRequired,
    show_venue: PropTypes.string.isRequired,
    show_location: PropTypes.string.isRequired,
    ext_on_sale_url: PropTypes.string,
    ext_url: PropTypes.string,
  })
}

PresaleListItem.defaultProps = {
  presale: {}
}

export default PresaleListItem;
