import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function NoContent(props) {
  return (
    <div className={classnames('rounded-sm shadow-sm bg-white text-center p-10', props.className)}>
      {props.children}
    </div>
  );
}

NoContent.propTypes = {
  children: PropTypes.node
}

export default NoContent;
