import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import Avatar from './Account/Avatar';
import Name from './Account/Name';
import Birthday from './Account/Birthday';
import Language from './Account/Language';

export default function Account() {
  const { session } = useContext(AuthContext);
  const profile = session?.data?.profile;

  return (
    <div className="mx-auto max-w-screen-lg pt-6 pb-12 px-4 lg:px-0">
      <header>
        <h1 className="font-normal text-2xl">Profile</h1>
      </header>
      <div className="bg-white overflow-hidden rounded-sm shadow-sm mt-6">
        <Avatar />
        <Name />
        <Birthday />

        <div className="flex justify-between items-center p-3 sm:p-4 border-t">
          <div>
            <p>Mobile Number</p>
            <p className="text-xs text-gray-600">{profile.phone}</p>
          </div>
          <a href="/update-phone" className="link-blue">
            Edit <i className="icon-chevron-right"></i>
          </a>
        </div>

        <div className="flex justify-between items-center p-3 sm:p-4 border-t">
          <div>
            <p>Email</p>
            <p className="text-xs text-gray-600">{profile.email}</p>
          </div>
          <a href="/login?state=7&redirect=settings" className="link-blue">
            Edit <i className="icon-chevron-right"></i>
          </a>
        </div>

        <div className="flex justify-between items-center p-3 sm:p-4 border-t">
          <div>
            <p>Password</p>
            <p className="text-xs text-gray-600">*******</p>
          </div>
          <a href="/update-password" className="link-blue">
            Edit <i className="icon-chevron-right"></i>
          </a>
        </div>

        <Language />
      </div>

      <header className="mt-6">
        <h1 className="font-normal text-2xl">Danger Zone</h1>
      </header>

      <div className="bg-white overflow-hidden rounded-sm shadow-sm mt-6">
        <div className="flex justify-between items-center p-3 sm:p-4 border-t">
          <div>
            <p>Deactivate Account</p>
            <p className="text-xs text-gray-600">
              Temporarily disabled your account and restrict access to your data.
            </p>
          </div>
          <Link
            to="/settings/deactivate-account"
            className="link-blue whitespace-no-wrap pl-3"
          >
            Deactivate <i className="icon-chevron-right"></i>
          </Link>
        </div>

        <div className="flex justify-between items-center p-3 sm:p-4 border-t">
          <div>
            <p>Delete Account</p>
            <p className="text-xs text-gray-600">
              Permanently delete your account. This action cannot be undone.
            </p>
          </div>
          <Link
            to="/settings/delete-account"
            className="link-blue whitespace-no-wrap pl-3"
          >
            Delete <i className="icon-chevron-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}
