import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import history from '../../history';
import useScrollTop from '../../hooks/useScrollTop';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import SearchBar from '../../components/SearchBar';
import FeaturedCampaignsGroup from '../../components/FeaturedCampaignsGroup';
// import NearbyCampaignsGroup from '../../components/NearbyCampaignsGroup';

import { ReactComponent as TunespeakLogo } from '../../assets/images/tunespeak_logo.svg';
import { ReactComponent as ClientsDesktopImg } from '../../assets/images/clients_desktop.svg';
import { ReactComponent as ClientsMobileImg } from '../../assets/images/clients_mobile.svg';
import handsBackImg from '../../assets/images/hands_back.svg';
import handsFrontImg from '../../assets/images/hands_front.svg';
import stageImg from '../../assets/images/home_stage.png';
import forTheArtistsImg from '../../assets/images/for_the_artists.svg';
import forbesLogoImg from '../../assets/images/forbes_logo.svg';
import winnerWallMobileImg from '../../assets/images/winner_wall_mobile.jpeg';
import winnerWallDesktopImg from '../../assets/images/winner_wall_desktop.jpeg';

import './Home.scss';

export function Home(props) {
  const submitSearch = (value) => {
    history.push(`search?q=${value}`);
  };

  useScrollTop(props);

  // if production allow robots to crawl page
  const robots =
    process.env.REACT_APP_ENV === 'production' ? (
      <meta name="robots" content="index, follow" />
    ) : null;

  return (
    <div className="Home min-h-screen">
      <Helmet>
        <title>Tunespeak</title>
        {robots}
      </Helmet>

      {/***  Splash ***/}
      <div className="splash">
        <div className="splash-bg relatie">
          <Navbar showLogo={false} className="relative z-10" />
          <div className="image-container back">
            <img src={handsBackImg} alt="" />
            <img src={handsBackImg} alt="" />
            <img src={handsBackImg} alt="" />
          </div>
          <div className="image-container front">
            <img src={handsFrontImg} alt="" />
            <img src={handsFrontImg} alt="" />
            <img src={handsFrontImg} alt="" />
          </div>
        </div>
        <div className="splash-content text-white">
          <TunespeakLogo className="mx-auto" />
          <p>Millions of fans rewarded by their favorite artists.</p>
          <a href="#campaigns">
            <i className="icon-chevron-down opacity-25 hover:opacity-100" />
          </a>
        </div>
        <img className="splash-stage" src={stageImg} alt="" />
      </div>

      {/***  Contests ***/}
      <section className="bg-gray-200" id="campaigns">
        <div className="max-w-screen-lg mx-auto pt-5 pb-6 px-3 md:pt-12 md:pb-16 md:px-6">
          <SearchBar onSubmit={submitSearch} />
          <FeaturedCampaignsGroup />
          {/*<NearbyCampaignsGroup />*/}
        </div>
      </section>

      {/***  Tom Quote ***/}
      <section className="bg-blue-gradient text-white sm:text-lg">
        <div className="max-w-screen-lg mx-auto py-20 px-8 sm:px-16 sm:py-24 text-center">
          <p className="italic font-light">
            <i className="icon-quote-open mr-2 md:mr-4 text-3xl"></i>
            We started <b className="font-bold">Tunespeak</b> with a simple mission -{' '}
            <b className="font-bold">to&nbsp;help&nbsp;artists&nbsp;identify</b>
            <br />
            <b className="font-bold">and reward their most passionate fans.</b>
          </p>
          <p className="font-bold mt-6">
            - Tom Pernikoff, <span className="font-normal">Co-Founder &amp; CEO</span>
          </p>
        </div>
      </section>

      {/***  Winner Wall ***/}
      <section className="w-full overflow-hidden bg-black relative" style={{height: '80vh'}}>
        <div className="winner-wall-bg bg-black w-full h-full">
          <div className="lg:hidden bg-cover bg-no-repeat w-full h-full opacity-25" style={{backgroundImage: `url(${winnerWallMobileImg})` }}/>
          <div className="lg:hidden bg-cover bg-no-repeat w-full h-full opacity-25" style={{backgroundImage: `url(${winnerWallMobileImg})` }}/>
          <div className="hidden lg:block bg-cover bg-no-repeat w-full h-full opacity-25" style={{backgroundImage: `url(${winnerWallDesktopImg})` }}/>
          <div className="hidden lg:block bg-cover bg-no-repeat w-full h-full opacity-25" style={{backgroundImage: `url(${winnerWallDesktopImg})` }}/>
        </div>
        <div className="absolute inset-0 w-full h-full flex flex-col justify-center items-center p-6">
          <h1 className="text-center text-3xl md:text-5xl font-bold text-white">Real&nbsp;Fans. Real&nbsp;Prizes.</h1>
          <a
            className="btn btn-white mt-6 w-48"
            href="http://tunespeakapp.tumblr.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SEE WINNERS
          </a>
        </div>
      </section>

      {/***  Hey Artists ***/}
      <section className="bg-gray-100">
        <div className="max-w-screen-xl mx-auto flex flex-col justify-center items-center md:flex-row py-16 px-8 sm:px-16 sm:py-18 text-center md:text-left">
          <img src={forTheArtistsImg} alt="" className="w-48 md:w-1/3" />
          <div className="md:ml-12 mt-6 md:mt-0">
            <h2 className="text-4xl md:text-5xl font-bold">For Artists</h2>
            <p className="text-lg font-light">
              Request a meeting with our Artist Relations team to find out why
              we're the #1 trusted fan engagement platform in the music industry,
              and how we can help you today!
            </p>
            <Link to="/for-artists/demo-request" className="btn btn-border mt-4">
              REQUEST A MEETING
            </Link>
          </div>
        </div>
      </section>

      {/***  Forbes Quote ***/}
      <section className="bg-gray-200 text-center md:text-right">
        <div className="max-w-screen-xl mx-auto flex flex-col justify-center items-center md:flex-row py-20 px-8 sm:px-16 sm:py-24">
          <div>
            <p className="text-lg">
              <i className="icon-quote-open text-2xl mr-3"></i>
              <span className="italic font-light">
                Tunespeak wants to help modernize the idea of a street team to
                assist musicians at all points in their careers spread the word
                about whatever it is they're doing and potentially increase their
                fan base.
              </span>
            </p>
            <p className="mt-4">
              - <span className="font-bold">HUGH MCINTYRE</span>
            </p>
          </div>
          <div className="mt-6 md:mt-0 md:ml-10 flex flex-col items-center">
            <img src={forbesLogoImg} alt="" className="h-8 md:h-12" />
            <a
              className="btn btn-border bg:transparent mt-8"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.forbes.com/sites/hughmcintyre/2017/06/26/tunespeak-rewards-fans-for-promoting-the-bands-they-already-love"
            >
              READ FULL ARTICLE
            </a>
          </div>
        </div>
      </section>

      {/***  Industry Leaders ***/}
      <section className="bg-gray-100">
        <div className="max-w-screen-xl mx-auto py-20 px-8 sm:px-16 sm:py-24 text-gray-500">
          <h2 className="text-center font-medium pb-12">
            TRUSTED BY INDUSTRY LEADERS
          </h2>
          <ClientsMobileImg className="md:hidden" />
          <ClientsDesktopImg className="hidden md:block" />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
