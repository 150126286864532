import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import useScrollTop from '../../hooks/useScrollTop';
import useRequest from '../../hooks/useRequest';
import useAsyncState from '../../hooks/useAsyncState';
import Loading from '../../components/Loading';

export default function EmailVerified(props) {
  useScrollTop(props);
  const { success, token, user_id } = queryString.parse(props.location.search);
  const [verifyReq, verify] = useRequest();
  const [verified, setVerified] = useAsyncState(success === 'true');

  useEffect(() => {
    if (success !== 'true' && success !== 'false' && user_id && token) {
      verify({
        type: 'get',
        path: `/verify_email?user_id=${user_id}&token=${token}`
      });
    }
  }, [success, token, user_id]);

  useEffect(() => {
    if (verifyReq.success && verifyReq?.data?.redirect_url) {
      if (verifyReq?.data?.verified) setVerified(true);
      window.top.location = verifyReq?.data?.redirect_url;
    }
  }, [verifyReq.success]);

  // if missing query-params redirect
  if (typeof success !== 'string' && !token && !user_id) {
    return <Redirect to="/settings/account" />;
  }

  return (
    <div className="mx-auto max-w-screen-lg pt-6 pb-12 px-4 lg:px-0">
      <header className="mt-3">
        <h1 className="font-normal text-2xl">
          {verifyReq.loading && 'Verifying Your Email'}
          {!verifyReq.loading && verified && 'Success, Email Verified!'}
          {!verifyReq.loading && !verified && "Couldn't Verify Your Email"}
        </h1>
      </header>
      {verifyReq.loading && <Loading />}
      {verified && (
        <p>
          You may update your email preferences in{' '}
          <Link to="/settings/notifications">Notifications Settings</Link>
        </p>
      )}
      {!verifyReq.loading && !verified && (
        <p>
          This verification link has expired or is invalid. Please check for a
          newer email, or resend the verification email from{' '}
          <Link to="/settings/account">Settings</Link>
        </p>
      )}
    </div>
  );
}
