import React from 'react';
import PropTypes from 'prop-types';
import PrizeIcon from '../PrizeIcon';
import './ContestChip.scss';

function ContestChip(props) {
  const { label, url, icon } = props;
  return (
    <a className="contest-chip" href={url}>
      <div className="contest-chip-content">
        <PrizeIcon icon={icon} />
        <p bablic-exclude="true" className="contest-chip-content__label">
          {label}
        </p>
      </div>
    </a>
  );
}

ContestChip.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string
}

export default ContestChip;
