import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import constants from '../../constants';
import useScrollTop from '../../hooks/useScrollTop';
import useRequest from '../../hooks/useRequest';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import ContestTileMedium from '../../components/ContestTileMedium';
import Loading from '../../components/Loading';
import NoContent from '../../components/NoContent';

import { ReactComponent as TunespeakLogo } from '../../assets/images/tunespeak_logo.svg';

import frFlag from '../../assets/images/flags/fr.png';
import deFlag from '../../assets/images/flags/de.png';
import ukFlag from '../../assets/images/flags/uk.png';

import frOgImage from '../../assets/images/social_posts/world-fr.png';
import deOgImage from '../../assets/images/social_posts/world-de.png';
import ukOgImage from '../../assets/images/social_posts/world-uk.png';

import frMap from '../../assets/images/maps/fr.png';
import deMap from '../../assets/images/maps/de.png';
import ukMap from '../../assets/images/maps/uk.png';

const COUNTRIES = {
  fr: {
    sweepstakesIn: 'Campagnes disponibles en',
    ogTitle: 'Gagnez des billets, des expériences VIP et plus encore!',
    ogImage: frOgImage,
    name: 'France',
    flag: frFlag,
    code: 'FR',
    map: frMap,
  },
  de: {
    sweepstakesIn: 'Gewinnspiel verfügbar in',
    ogTitle: 'Gewinnen Sie Tickets, VIP-Erlebnisse und mehr!',
    ogImage: deOgImage,
    name: 'Deutschland',
    flag: deFlag,
    code: 'DE',
    map: deMap,
  },
  uk: {
    sweepstakesIn: 'Sweepstakes available in the',
    ogTitle: 'Win tickets, VIP experiences and more!',
    ogImage: ukOgImage,
    name: 'United Kingdom',
    flag: ukFlag,
    code: 'GB',
    map: ukMap,
  }
}

function World(props) {
  useScrollTop(props);
  const country = props.match.params.country;
  const config = COUNTRIES[country];
  const [req] = useRequest({
    type: 'get',
    path: `/featured_campaigns/search?limit=6&country=${config.code}`,
    serializer: (res) => res.search_results
  });

  if (!config) {
    return (<Redirect to="/" />);
  }

  // build dom nodes
  let searchResultTiles = <NoContent>No campaigns available.</NoContent>
  if (req.loading) {
    searchResultTiles = <Loading />
  } else if (req?.data?.length) {
    searchResultTiles = (
      <div className="flex flex-wrap -mx-4">
        {req.data.map(fc => <div key={fc.id} className="w-full sm:w-1/2 md:w-1/3 p-4">
          <ContestTileMedium contest={fc}/>
        </div>)}
      </div>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title bablic-exclude="true">Tunespeak / {config.name}</title>
        <meta property="og:title" content={config.ogTitle} />
        <meta property="og:image" content={`${constants.REACT_APP_URL}${config.ogImage}`} />
        <meta name="twitter:title" content={config.ogTitle} />
        <meta name="twitter:image" content={`${constants.REACT_APP_URL}${config.ogImage}`} />
      </Helmet>

      <div className="min-h-screen bg-gray-200">
        <div className="bg-blue-gradient">
          <div className="bg-right-top bg-contain bg-no-repeat" bablic-exclude="true" style={{backgroundImage: `url(${config.map})`}}>
            <Navbar showLogo={false} className="bg-transparent text-white" />

            <div className="max-w-screen-lg mx-auto px-4 lg:px-0 pt-16 pb-28"> 
              <div className="text-white">
                <TunespeakLogo className="w-4/5 sm:w-2/5 mb-2" />
                <p className="sm:text-xl font-thin tracking-wide ml-2">
                  {config.sweepstakesIn}&nbsp;<b className="font-bold">{config.name}</b> <img src={config.flag} className="inline ml-1 h-8" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-screen-lg mx-auto p-4 lg:px-0 md:py-8">
          {searchResultTiles}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default World;
