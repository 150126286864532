import React, { Fragment } from 'react';
import lockImage from '../../assets/images/lock.png';

const RequiresPassword = (props) => {
  const {
    children,
    errors,
    cookie = 'password',
    expiresIn = 604800, // seconds (7 day default)
  } = props;

  const passwordRequired = errors &&
                           errors &&
                           errors.status === 403;

  const submitPassword = (e) => {
    e.preventDefault();

    const value = e.target.password.value;
    const domain = window.location.hostname.replace('www', '');
    
    let expires;
    if (expiresIn) {
      const d = new Date();
      d.setSeconds(d.getSeconds() + expiresIn);
      expires = `expires=${d.toString()}; `;
    }
    
    document.cookie = `${cookie}=${value}; ${expires}domain=${domain}; path=/`;
    window.location.reload();
  }

  return (
    <Fragment>
      { passwordRequired ?
        <div className="bg-gray-200 flex justify-center items-center h-screen p-4">
          <form onSubmit={submitPassword}>
            <img src={lockImage} className="w-16 mb-4 block mx-auto" />
            <input className="text-center border-box text-lg p-4 my-4 w-full" type="password" name="password" placeholder="What's the magic word?" />
            <input className="btn btn-border w-full" type="submit" value="SUBMIT" />
          </form>
        </div> : children }
    </Fragment>
  );
}

export default RequiresPassword;
