
import constants from '../constants';

export default function () {
  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', constants.KEYS.googleAnalytics.gaMeasurementId);

  window.initGtag = function () {
    const script = document.createElement('script');
    const el = document.getElementsByTagName('script')[0];
    script.async = 1;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${constants.KEYS.googleAnalytics.gaMeasurementId}`;
    el.parentNode.insertBefore(script, el);
  }
}