import { useEffect, useState, useRef } from 'react';

const useAsyncState = (initialValue) => {
  const ref = useRef(true);
  const [state, setState] = useState(initialValue);

  const setValue = (value) => {
    if (ref.current) {
      setState(value);
    }
  }

  useEffect(() => {
    return () => {
      ref.current = false;
    }
  }, []);

  return [state, setValue];
}

export default useAsyncState;