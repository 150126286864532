import React, { Fragment, Suspense } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useScrollTop from '../../hooks/useScrollTop';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import jobsBanner from '../../assets/images/jobs-banner.png';
import List from '../../pages/Jobs/List';
const ServerDeveloper = React.lazy(() => import('../../pages/Jobs/ServerDeveloper'));
const BizOperationsManager = React.lazy(() => import('../../pages/Jobs/BizOperationsManager'));


function Jobs(props) {
  useScrollTop(props);
  return (
    <Fragment>
      <Helmet>
        <title>Tunespeak / Jobs</title>
      </Helmet>
      <div className="bg-gray-100 min-h-screen">
        <Navbar className="bg-blue-gradient text-white"/>
        <div className="bg-blue-500 shadow-sm">
          <div className="max-w-screen-xl mx-auto">
            <img src={jobsBanner} />         
          </div>
        </div>
        <div className="max-w-screen-lg mx-auto py-8 px-6 space-y-4" bablic-exclude="true">
          <Switch>
            <Route path="/jobs/server-developer" render={ props =>
              <Suspense fallback={null}>
                <ServerDeveloper {...props}/>
              </Suspense> }>
            </Route>
            <Route path="/jobs/biz-operations-manager" render={ props =>
              <Suspense fallback={null}>
                <BizOperationsManager {...props}/>
              </Suspense> }>
            </Route>
            <Route path="/jobs" exact component={List} />
            <Route path="*">
              <Redirect to="/jobs" />
            </Route>
          </Switch>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Jobs;