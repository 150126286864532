import isMobile from 'ismobilejs';
import storage from './storage';

// Embedded iOS webview check
export function isEmbeddediOSWebView() {
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  return isMobile(userAgent).apple.device && !safari;
};

// Embedded Android webview check
export function isEmbeddedAndroidWebView() {
  const userAgent = window.navigator.userAgent;
  return isMobile(userAgent).android.device && storage.getRawCookie('em_android') === '1';
};

// Embedded webview check
export default function isEmbeddedWebView() {
  return isEmbeddediOSWebView() || isEmbeddedAndroidWebView();
};