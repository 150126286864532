import React, { useState, useContext, Fragment } from 'react';
import Modal from '../../components/Modal';
import AuthContext from '../../contexts/AuthContext';
import useAsyncState from '../../hooks/useAsyncState';
import ENV from '../../constants';
import TS from '../../api';

export default function Account(props) {
  const { session, update: updateSession } = useContext(AuthContext);
  const user = session?.data;
  const [selectedService, setSelectedService] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [services, setServices] = useAsyncState({
    facebook: {
      name: 'Facebook',
      icon: 'icon-facebook text-facebook',
      description: `Facebook is used primarly for authentication. We may also use your age, likes, and location to tailor your experience.`,
      connected: user.facebook,
      url: `${ENV.API_ROOT}/auth/facebook?return_url=${window.location.href}`,
      account: (
        <Fragment>
          <img
            src={user?.facebook?.photo}
            alt=""
            className="h-3 w-3 rounded-full mr-1"
          />{' '}
          {user?.facebook?.name}
        </Fragment>
      )
    },
    spotify: {
      name: 'Spotify',
      icon: 'icon-spotify text-spotify',
      description: `Spotify is used to power the "Play", "Follow", "Save", and "Add to Playlist" buttons on Tunespeak campaigns.`,
      connected: user.spotify,
      url: `${ENV.API_ROOT}/auth/spotify?show_dialog=true&return_url=${window.location.href}`,
      account: (
        <Fragment>
          <img
            src={user?.spotify?.photo}
            alt=""
            className="h-3 w-3 rounded-full mr-1"
          />{' '}
          {user?.spotify?.username}
        </Fragment>
      )
    },
    twitter: {
      name: 'Twitter',
      icon: 'icon-twitter text-twitter',
      description: `Twitter is used to power the "Follow" button on Tunespeak campaigns.`,
      connected: user.twitter,
      url: `${ENV.API_ROOT}/auth/twitter?force_login=true&return_url=${window.location.href}`,
      account: (
        <Fragment>
          <img
            src={user?.twitter?.photo_url}
            alt=""
            className="h-3 w-3 rounded-full mr-1"
          />{' '}
          {user?.twitter?.username}
        </Fragment>
      )
    },
    youtube: {
      name: 'Youtube',
      icon: 'icon-youtube text-youtube',
      description: `Youtube is used to power the "Subscribe" button and "Watch" actions on Tunespeak campaigns.`,
      connected: user.youtube,
      url: `${ENV.API_ROOT}/auth/youtube?return_url=${window.location.href}`,
      account: (
        <Fragment>
          <img
            src={user?.youtube?.photo_url}
            alt=""
            className="h-3 w-3 rounded-full mr-1"
          />{' '}
          {user?.youtube?.username}
        </Fragment>
      )
    },
    google: {
      name: 'Google',
      icon: 'icon-google text-google',
      description: `Google is used primarly for authentication. We may also use your age.`,
      connected: user.google,
      url: `${ENV.API_ROOT}/auth/google?return_url=${window.location.href}`,
      account: (
        <Fragment>
          <img
            src={user?.google?.photo_url}
            alt=""
            className="h-3 w-3 rounded-full mr-1"
          />{' '}
          {user?.google?.name}
        </Fragment>
      )
    },
    soundcloud: {
      name: 'Soundcloud',
      icon: 'icon-soundcloud text-soundcloud',
      description: `Soundcloud is used to power the "Play", "Follow", and "Save" buttons on Tunespeak campaigns.`,
      connected: user.soundcloud,
      url: `${ENV.API_ROOT}/auth/soundcloud?return_url=${window.location.href}`,
      account: (
        <Fragment>
          <img
            src={user?.soundcloud?.photo_url}
            alt=""
            className="h-3 w-3 rounded-full mr-1"
          />{' '}
          {user?.soundcloud?.username}
        </Fragment>
      )
    }
  });

  const handleClick = (service) => {
    if (service === 'facebook' || service === 'google') {
      setSelectedService(service);
      setShowModal(true);
    } else {
      disconnectService(service);
    }
  };

  const disconnectService = (service = '') => {
    setShowModal(false);
    TS.delete(`/user_${service}`).then(() => {
      updateSession((prev) => {
        const ret = Object.assign({}, prev);
        ret.data[service] = null;
        return ret;
      });
      setServices((prev) => {
        const ret = Object.assign({}, prev);
        ret[service].connected = false;
        return ret;
      });
    });
  };

  return (
    <div className="mx-auto max-w-screen-lg pt-6 pb-12 px-4 lg:px-0">
      <header className="mt-3">
        <h1 className="font-normal text-2xl">Connected Apps</h1>
        <p className="text-sm">
          Manage connected third-party applications used for logging in and other campaign actions.
        </p>
      </header>
      <div className="bg-white overflow-hidden rounded-sm shadow-sm mt-6">
        {Object.values(services).map((service) => (
          <div
            className="flex flex-col sm:flex-row sm:items-center px-3 py-6 sm:p-4 border-t first:border-t-0"
            key={service.name}
          >
            <i className={`${service.icon} text-center text-3xl`}></i>
            <div className="pt-3 sm:pl-3 sm:pt-0 w-full">
              <p className="">{service.name}</p>
              <p className="text-gray-500 text-xs leading-snug">
                {service.description}
              </p>
              {service.connected && (
                <p className="flex items-center text-xs">{service.account}</p>
              )}
            </div>
            {service.connected ? (
              <button
                className="btn btn-border-red btn-round mt-3 min-w-0 w-full sm:ml-3 sm:mt-0 sm:w-48"
                onClick={() => handleClick(service.name.toLowerCase())}
              >
                Disconnect
              </button>
            ) : (
              <a
                href={service.url}
                className="btn btn-border btn-round mt-3 min-w-0 w-full sm:ml-3 sm:mt-0 sm:w-48"
              >
                Connect
              </a>
            )}
          </div>
        ))}
      </div>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="bg-white rounded p-4 lg:p-6">
          <h1 className="font-normal text-2xl leading-snug">
            Are you sure you want to disconnect{' '}
            <span className="capitalize">{selectedService}</span>?
          </h1>
          <p className="mt-3">
            <b>WARNING:</b> If you created your account with{' '}
            <span className="capitalize">{selectedService}</span>, you will need
            to{' '}
            <a href="/update-password" className="link-blue">
              set a password
            </a>{' '}
            before disconnecting so you can log back in.
          </p>
          <button
            className="btn btn-border-red btn-round mt-6 w-full"
            onClick={() => disconnectService(selectedService)}
          >
            Disconnect
          </button>
          <button
            className="btn btn-border btn-round mt-3 w-full"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
}
