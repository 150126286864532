import React, { useState, useContext } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import useAsyncState from '../../../hooks/useAsyncState';
import Modal from '../../../components/Modal';
import TS from '../../../api';

export default function Name() {
  const { session, update: updateSession } = useContext(AuthContext);
  const profile = session?.data?.profile;
  const [loading, setLoading] = useAsyncState(false);
  const [isEditingName, setIsEditingName] = useAsyncState(false);
  const [firstName, setFirstName] = useState(profile.first_name);
  const [lastName, setLastName] = useState(profile.last_name);

  const updateName = () => {
    if (!loading) {
      setLoading(true);
      TS.put(`/user_profiles/${profile.id}`, {
        user_profile: {
          ...profile,
          first_name: firstName.trim(),
          last_name: lastName.trim()
        }
      }).then((res) => {
        setLoading(false);
        setIsEditingName(false);
        updateSession((prev) => {
          const ret = Object.assign({}, prev);
          ret.data.profile = res.user_profile;
          return ret;
        });
      }).catch(() => setLoading(false));
    }
  };

  return (
    <div className="flex justify-between items-center p-3 sm:p-4 border-t">
      <div>
        <p>Name</p>
        <p bablic-exclude="true" className="text-xs text-gray-600">
          {profile.first_name} {profile.last_name}
        </p>
      </div>
      <button className="btn btn-border btn-round" onClick={() => setIsEditingName(true)}>
        Edit
      </button>
      <Modal open={isEditingName} onClose={() => setIsEditingName(false)}>
        <div className="bg-white rounded p-4 lg:p-6">
          <div>
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                First Name
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="first_name"
                  className="form-input block w-full sm:text-sm sm:leading-5"
                  placeholder="John"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>

            <div className="mt-3">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Last Name
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="last_name"
                  className="form-input block w-full sm:text-sm sm:leading-5"
                  placeholder="Doe"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <button className="btn btn-border btn-round w-full mt-6" onClick={updateName}>
            {loading ? <i className="icon-loading"></i> : 'Save'}
          </button>
        </div>
      </Modal>
    </div>
  );
}
