import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import useScrollTop from '../../hooks/useScrollTop';
import AuthContext from '../../contexts/AuthContext';
import TS from '../../api';

export default function DeactivateAccount(props) {
  useScrollTop(props);
  const { session, update: updateSession } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState('');
  const [reasons, setReasons] = useState({
    1: {
      label: "Don't like it",
      value: false
    },
    2: {
      label: "Don't use it",
      value: false
    },
    3: {
      label: 'Not enough artists',
      value: false
    },
    4: {
      label: 'Never win',
      value: false
    },
    5: {
      label: 'To restrict my data',
      value: false
    },
    6: {
      label: 'Other',
      value: false
    }
  });

  const buildReasonsString = () => {
    let ret = [];
    Object.values(reasons).forEach((reason) => {
      if (reason.value) {
        ret.push(reason.label);
      }
    });
    return ret.join(', ').trim();
  };

  const handleChange = (e) => {
    const id = e.target.id;
    const checked = e.target.checked;
    setReasons((prev) => ({
      ...prev,
      [id]: {
        label: prev[id].label,
        value: checked
      }
    }));
  };

  const deactivate = () => {
    setLoading(true);
    TS.post('/user_deactivations', {
      user_deactivation: {
        reason: buildReasonsString(),
        comment: comments
      }
    }).then(() => {
      setLoading(false);
      updateSession((prev) => {
        const ret = Object.assign({}, prev);
        ret.data.deactivated = true;
        return ret;
      });
    });
  };

  const reactivate = () => {
    setLoading(true);
    TS.delete('/user_deactivations').then(() => {
      setLoading(false);
      updateSession((prev) => {
        const ret = Object.assign({}, prev);
        ret.data.deactivated = false;
        return ret;
      });
    });
  };

  return (
    <div className="mx-auto max-w-screen-lg pt-6 pb-12 px-4 lg:px-0">
      <Link to="/settings/account" className="link-blue text-sm">
        <i className="icon-arrow-left"></i> Back to Account
      </Link>
      {session?.data?.deactivated ? (
        <div>
          <header className="mt-3">
            <h1 className="font-normal text-2xl">Account Deactivated</h1>
            <p className="text-sm">
              Your data is only available to Tunespeak administrators and is not
              available to any artist, artist representative, or Tunespeak
              partner to use for any purpose including marketing, rewards,
              exclusive content, etc.
            </p>
          </header>
          <button
            className="btn btn-border btn-round mt-6 w-full"
            onClick={reactivate}
          >
            {loading ? <i className="icon-loading"></i> : 'Reactivate'}
          </button>
        </div>
      ) : (
        <div>
          <header className="mt-3">
            <h1 className="font-normal text-2xl">Deactivate Account</h1>
            <p className="text-sm">
              By deactivating your account your data will not be deleted, but
              access to your data will be restricted to Tunespeak administrators
              for customer service purposes. You will not be eligible to win any
              campaigns while your account is deactivated.
            </p>
          </header>
          <p className="font-normal text-xl mt-6">
            Please let us know why you're taking a break
          </p>
          <div className="flex flex-wrap pt-2">
            {Object.keys(reasons).map((key) => (
              <div className="flex items-center w-1/2 pb-2" key={key}>
                <input
                  id={key}
                  type="checkbox"
                  value={reasons[key].value}
                  onChange={handleChange}
                  className="form-checkbox h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                />
                <div className="ml-3 text-sm leading-5">
                  <label htmlFor={key} className="font-medium text-gray-700">
                    {reasons[key].label}
                  </label>
                </div>
              </div>
            ))}
          </div>
          <div className="rounded-md shadow-sm">
            <textarea
              id="about"
              rows="3"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              placeholder="Comments"
            ></textarea>
          </div>
          <button
            className="btn btn-border btn-round mt-6 w-full"
            onClick={deactivate}
          >
            {loading ? <i className="icon-loading"></i> : 'Deactivate'}
          </button>
        </div>
      )}
    </div>
  );
}
