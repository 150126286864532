import React, { useState, useEffect, useRef } from 'react';
import ContestTileMedium from '../ContestTileMedium';
import Loading from '../Loading';
import NoContent from '../NoContent';
import useRequest from '../../hooks/useRequest';

function FeaturedCampaignsGroup() {
  const elRef = useRef(null);
  const [req, fetchCampaigns] = useRequest();
  const [hasMore, setHasMore] = useState(true);
  const loadMore = function () {
    setHasMore(false);
    fetchFeaturedCampaigns(60);
  }

  const fetchFeaturedCampaigns = (limit) => {
    fetchCampaigns({
      type: 'get',
      path: `featured_campaigns?limit=${limit}`,
      serializer: (res) => res.featured_campaigns
    });
  }

  // fetch campaigns
  useEffect(() => {
    fetchFeaturedCampaigns(6)
  }, []);

  // Keep in view
  useEffect(() => {
    if (!hasMore && elRef && elRef.current && elRef.current.offsetTop && window.scrollTo) {
      window.scrollTo(0, elRef.current.offsetTop + parseInt(-150));
    }
  }, [req.loading]);

  // build dom nodes
  let featuredCampaignTiles = <NoContent>No featured campaigns.</NoContent>
  if (req.loading) {
    featuredCampaignTiles = <Loading />
  } else if (req?.data?.length) {
    featuredCampaignTiles = (
      <div className="flex flex-wrap -mx-4">
        {req.data.map(fc => <div key={fc.id} className="w-full sm:w-1/2 md:w-1/3 p-4">
          <ContestTileMedium contest={fc}/>
        </div>)}
      </div>
    );
  }

  let loadMoreBtn = null;
  if (!req.loading && hasMore && req?.data?.length) {
    loadMoreBtn = <div className="mt-4 flex justify-center items-center">
      <button className="btn btn-border bg-transparent w-48" onClick={loadMore}>VIEW MORE</button>
    </div>
  }

  return (
    <div ref={elRef} className="w-full mt-4">
      {featuredCampaignTiles}
      {loadMoreBtn}
    </div>
  );
}

export default FeaturedCampaignsGroup;
