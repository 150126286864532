import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Transition } from '@headlessui/react';

function Drawer(props) {
  const { open, onClose } = props;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.addEventListener("keydown", escFunction, false);
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener("keydown", escFunction, false);
    }
  }, [open])

  const escFunction = useCallback((event) => {
    if(event.keyCode === 27) {
      onClose();
    }
  }, []);

  return (
     <Transition show={open}>
      <Transition.Child
        className="fixed z-50 inset-0"
        enter="transition-opacity duration-300 linear"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200 linear"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        onClick={onClose}
      >
        <div className="absolute inset-0 bg-black opacity-75"></div>
      </Transition.Child>
      <Transition.Child
        className="fixed z-50 min-h-screen w-64 flex flex-col flex-grow border-r border-gray-200 bg-white overflow-y-auto"
        enter="transition transform duration-300 linear"
        enterFrom="-translate-x-64"
        enterTo="translate-x-0"
        leave="transition transform duration-200 linear"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-64"
      >
        {props.children}
      </Transition.Child>
    </Transition>
  );
}

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default Drawer;
